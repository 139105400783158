.ftop {
  background-color: $color-grey-6;
  padding: $font-size 0;

  &__container {
  }

  &__items {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: $space-1;
    @media screen and (max-width: 767px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: calc($font-size / 2);
      align-items: flex-start;
    }
  }

  &__item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $font-size;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      text-align: center;
      gap: calc($font-size * 0.5);
    }
    @media screen and (max-width: 767px) {
      display: grid;
      grid-template-columns: 20px 1fr;
      gap: calc($font-size / 2);
      align-items: flex-start;
      text-align: initial;
    }
  }

  &__cover {
    @media screen and (max-width: 767px) {
      flex: 0 0 20px;
    }
    img,
    svg {
      width: 30px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 20px;
        height: auto;
      }
    }
  }

  &__content {
  }

  &__title {
    font-weight: $font-weight-bold;
    @media screen and (max-width: 767px) {
      font-size: $font-size-smaller;
    }
  }

  &__subtitle {
    font-size: $font-size-small;
    @media screen and (max-width: 767px) {
      font-size: $font-size-smaller;
    }
  }
}

.home-categorias {
  padding: $space-2 0;
  &__items {
  }

  &__item {
    position: relative;
  }

  &__img {
    padding: 0 0.5rem;
    img {
      border-radius: $border-radius;
      aspect-ratio: 16 / 10;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  &__info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $space-1;
  }

  &__titulo {
    color: $color-white;
    text-align: center;
    font-size: $header-5;
    background-color: $color-dark;
    padding: 0.7rem $space-1;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.3);
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
  }
}

.productos-destacados {
  padding: $space-2 0 $space-4 0;

  &__content {
  }

  &__items {
  }
}

.productos-all {
  padding: $space-3 0;

  &__content {
  }

  &__items {
  }

  &__item {
    position: relative;
    margin: 0 2px;
    img {
      position: relative;
      z-index: 1;
      border-radius: 3px;
      aspect-ratio: 16/11;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__item-overlay {
    position: absolute;
    background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    opacity: 57%;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    border-radius: 3px;
  }

  &__item-titulo {
    position: absolute;
    z-index: 3;
    left: $space-1;
    bottom: $space-1;
    font-size: $header-2;
    line-height: 1;
    color: $color-white;
    font-weight: $font-weight-bold;
  }
}