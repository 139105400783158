@mixin button {
  padding: 14px 24px; // cambiar tamaño
  font-size: 1rem;
  // text-transform: uppercase;
  border-radius: 50px; // cambiar radius
  font-weight: $font-weight-bold;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  border: 0;
  transition: $trans;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-width: 2px;
  border-style: solid;
  &--lg {
    padding: 1.125rem 1.9375rem;
    @media screen and (max-width: 767px) {
      padding: 1rem 1.475rem;
    }
  }
  &--sm {
    padding: 8px 14px;
    font-size: $font-size-small;
  }
}

@mixin input-buttons {
  cursor: pointer;
  padding: 0.625rem 1.875rem;
  border: 2px;
  border-radius: 5px;
  letter-spacing: 1px;
  font-size: $font-size-small;
  font-weight: $font-weight-bold;
  box-shadow: none;
  display: inline-flex;
  align-items: center;
  box-shadow: none;
  height: auto;
  letter-spacing: 0;
  transition: $trans;
  @media (max-width: 575px) {
    padding: 0.75rem 1.2rem;
  }
  @media (max-width: 390px) {
    padding: 0.7rem 1rem;
    font-size: 0.8rem;
  }
  .bi {
    margin-left: 5px;
  }
}

@mixin input-buttons-lg {
  padding: 1.3rem 1.5rem;
  border: 2px;
  letter-spacing: 1px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: $trans;
}

@mixin input-buttons-sm {
  padding: 0.55rem 1.2rem;
  letter-spacing: 0px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: $trans;
}

@mixin input-buttons-hover {
  transform: translateY(-2px);
  box-shadow: $box-shadow-hover;
  transition: $trans;
}

@mixin cart-count($top, $right, $size) {
  display: inline-block;
  position: absolute;
  background-color: $color-secundario;
  padding: 0px 2px;
  border-radius: 3px;
  text-align: center;
  right: $right;
  top: $top;
  color: #fff;
  font-size: $size;
}

@mixin card-yellow($padding-card) {
  background-color: rgba($color: $color-secondary, $alpha: 0.1);
  padding: $padding-card;
  border-radius: 5px;
  border: 2px dashed lighten($color-secondary, 30%);
}

@mixin img-rounded {
  box-shadow: $box-shadow;
  border-radius: $border-radius;
}

@mixin card-cart {
  background-color: $color-background;
  box-shadow: $box-shadow-small;
  padding: 2rem;
  border-radius: $border-radius-min;
  @media (max-width: 575px) {
    padding: 1rem;
  }
}
