// Grid -------------------------------
div.product {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: auto;
  column-gap: 2.5rem;
  row-gap: 5rem;
  position: relative;
  @media (max-width: 767px) {
    display: block;
  }

  .woocommerce-product-gallery {
    grid-column: 1 / 6;
    grid-row: 1 / 2;
    position: relative;
  }

  .summary {
    grid-column: 6 / 11;
    grid-row: 1 / 2;
    @media screen and (max-width: 991px) {
      grid-column: 6 / 11;
    }
  }

  .woocommerce-tabs {
    grid-column: 1 / 6;
    grid-row: 2 / 3;
  }

  .related {
    grid-column: 6 / 11;
    grid-row: 2 / 3;
  }

  .up-sells {
    grid-column: 1 / 11;
    grid-row: 3 / 4;
  }
}

.summary {
  @media (max-width: 767px) {
    margin-bottom: 3rem !important;
  }
  @media screen and (max-width: 575px) {
    margin-bottom: 3rem;
  }

  .product_title {
    margin-top: 0;
    padding-top: 0;
    font-size: $header-3 !important;
    font-weight: $font-weight-bolder;
    margin-bottom: 0rem;
    color: $color-dark;
    margin-bottom: $font-size;
    text-wrap: balance;
    @media (max-width: 575px) {
      margin-bottom: $font-size-smaller;
      font-size: $font-size !important;
      margin-bottom: calc($font-size-smaller / 2);
      font-family: $typo-primary;
    }
  }

  ins {
    text-decoration: none !important;
    bdi {
      color: red;
      font-weight: $font-weight-bold;
    }
  }
  del {
    margin-right: 0.5rem;
    text-decoration: none !important;
    bdi {
      font-size: 70%;
      color: $color-grey-4;
    }
  }

  .screen-reader-text {
    display: none;
  }

  .cart.variations_form {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
  }

  .cart {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    .woocommerce-variation-add-to-cart {
      display: flex;
      align-items: center;
    }
  }

  .qib-container:not(#qib_id):not(#qib_id) {
    display: flex !important;
    align-items: center !important;
  }

  .cart .qty {
    padding: 15px 10px;
    font-size: 1rem;
    text-align: center;
    box-shadow: none;
    @media (max-width: 575px) {
      padding: 10px 5px;
      width: 80px;
    }
  }

  .quantity {
    margin-right: 1rem;
    display: flex;
    align-items: center;
    &.hidden {
      display: none;
    }

    .qty {
      height: 45px;
      width: 45px;
      border-radius: 0;
      border: 1px solid $color-text-body;
      border-right: none;
      border-left: none;
    }

    .button {
      height: 45px;
      width: 30px;
      padding: 0;
      background-color: transparent;
      color: $color-text-body;
      border: 1px solid $color-text-body;
      display: flex;
      justify-content: center;
      &:hover {
        transform: none;
      }
      &.minus {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-right: none;
      }
      &.plus {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-left: none;
      }
    }
  }

  .woocommerce-variation-add-to-cart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // .quantity {
    //   flex: 1 1 30%;
    // }
    // .single_add_to_cart_button {
    //   width: 100%;
    //   flex: 1 1 80% !important;
    // }
  }

  .price {
    margin: 0 0 1rem;
    font-weight: normal;
    color: $color-accent;
    font-size: $header-3;

    @media (max-width: 575px) {
      border: none;
      margin: 0 0 1rem;
      padding: 0;
    }

    @media (max-width: 390px) {
      margin: 0 0 0.5rem;
    }
  }
  .stock {
    font-size: $font-size-smaller;
    color: $color-grey-3;
    &.in-stock {
      color: green;
      font-weight: $font-weight-bold;
    }
    &.out-of-stock {
      color: red;
    }
  }
  .woocommerce-product-details__short-description {
    margin-bottom: $space-1;
    font-size: $font-size;
    color: $color-grey-2;
    font-family: $typo-primary;
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
    span {
      font-family: $typo-primary !important;
      font-size: $font-size !important;
      text-align: left !important;
    }
    img {
      display: none;
    }
  }

  table.variations {
    // margin-bottom: 1rem;

    label {
      font-weight: bold;
      text-transform: uppercase;
      font-size: $font-size-smaller;
      letter-spacing: 1px;
      color: $color-grey-2;
    }

    .reset_variations {
      font-size: 12px;
      color: red;
    }
  }
  .product_meta {
    font-size: 0.8rem;
    margin-bottom: 1rem;
    font-family: $typo-primary;
    display: none;

    & > span {
      display: block;
    }
    a {
      color: $color-grey-2;
      text-decoration: underline;
    }
  }

  .logo-marca {
    margin-bottom: $space-1;
    display: flex;
    &__img {
      width: 100px;
      height: auto;
    }
  }

  .woocommerce-variation-price {
    margin-bottom: 0.25rem;
    font-size: 120% !important;
  }
  .woocommerce-variation-peso-neto {
    margin-bottom: 0.8rem;
    font-size: 0.8rem;
  }
}

// Titulo y Summary -------------------------------
.product_meta {
}

.vi-wpvs-variation-wrap-image {
  .vi-wpvs-option-wrap {
    padding: 2px !important;
    border-radius: 0 !important;
    width: 70px !important;
    height: 70px !important;
    .vi-wpvs-option-image {
      width: 70px !important;
      height: 70px !important;
    }
  }
}

// Agrupados Colecciones -------------------------------
.coleccion {
  margin-bottom: 2rem;

  &__item {
    display: grid;
    grid-template-columns: 60px 50px auto 70px;
    grid-gap: 1rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 4px;
  }

  &__foto img {
    width: 50px;
    height: auto;
    border-radius: 2px;
  }

  &__quantity label {
    display: none;
  }

  &__quantity .quantity input {
    width: 100%;
    padding: 0.75rem 0.5rem !important;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: $color-grey-3;
    min-width: 50px;
  }

  &__nombre .precio-metro {
    font-size: 0.8rem;
    background-color: $color-grey-4;
    color: rgba(0, 0, 0, 0.6);
    padding: 1px 4px;
    border-radius: 3px;
  }

  &__label label {
    display: flex;
    margin-bottom: 3px;
  }

  &__label label a {
    font-size: 13px;
    font-weight: 400;
    color: $color-grey-2;
  }

  &__price {
    text-align: right;
    font-size: 1rem;
    color: $color-primary;
    font-weight: 700;
    .stock {
      margin: 0;
      font-size: $font-size-smaller;
      color: $color-grey-3;
      &.out-of-stock {
      }
    }
  }
}

.garantia-extras {
  font-size: $font-size-small;
  @media screen and (max-width: 575px) {
    // margin-bottom: 1rem!important;
  }

  a {
    color: $color-grey-2;
    border-bottom: 1px solid;
    font-weight: normal;
  }

  &__nav {
    list-style: none;
    padding-left: 0;
  }
  &__item {
    margin-bottom: 5px;
    @media (max-width: 575px) {
      margin-bottom: 6px;
    }
    .bi {
      margin-right: 5px;
    }

    &--share {
      // padding-bottom: 8px;
      a {
        border-bottom: 0;
        font-weight: bold;
        color: $color-whatsapp-dark;
      }
    }
  }

  a.verde-whatsapp {
    color: $color-whatsapp-dark;
    @media (max-width: 575px) {
      background-color: $color-whatsapp-dark;
      color: $color-background;
      display: inline-block;
      border-radius: 50px;
      padding: 0 8px;
      text-decoration: none;
    }
  }
}

// TABS -------------------------------
// .woocommerce-tabs {
//   @media (max-width: 767px) {
//     margin-bottom: $space-5;
//   }

//   @media screen and (max-width: 575px) {
//     background-color: $color-grey-6;
//     padding-top: 2rem;
//     padding-bottom: 1rem;
//     padding-left: 12px;
//     padding-right: 12px;
//     margin-left: -11px;
//     margin-right: -11px;
//     margin-bottom: 3rem;
//   }

//   .wc-tab h2 {
//     margin-top: 0;
//     font-size: 1.5rem;
//     @media (max-width: 767px) {
//       display: none;
//     }
//   }

//   .wc-tab {
//     // padding: 2rem;
//     padding-top: 1rem;
//     border-radius: $border-radius;
//     @media screen and (max-width: 575px) {
//       padding-top: 1rem;
//     }
//     & > div {
//       font-family: $typo-alternative;
//     }
//   }
//   #tab-description {
//     p,
//     ul,
//     h2,
//     h3,
//     h4,
//     h5 {
//       margin-bottom: 1.2rem;
//     }
//     h3 {
//       font-size: $header-4;
//       font-weight: 700;
//       color: $color-accent;
//     }
//   }
// }

.woocommerce-Tabs-panel {
  background-color: $color-background;
  padding: 2rem;
  border: 1px solid #dedede;
  border-radius: 0 4px 4px 4px;
  @media (max-width: 575px) {
    padding: 0rem;
    background-color: initial;
    border: 0;
    padding-top: $space-1;
  }
  > h2:first-child {
    font-weight: $font-weight-medium;
    font-size: $font-size-smaller;
    text-transform: uppercase;
    color: $color-primary-dark;
  }

  h2,
  h3,
  p,
  ul,
  ol {
    margin-bottom: $font-size;
  }

  ul,
  ol {
    margin-bottom: $space-1;
  }

  ul {
    padding-left: $font-size;
    li {
      padding-left: $space-1;
      position: relative;
      list-style: none;
      &::before {
        content: "\e9c3";
        font-family: "unicons-line";
        position: absolute;
        left: 0;
        top: 1px;
        color: $color-accent;
      }
    }
  }

  h2 {
    font-size: $header-3;
    font-weight: $font-weight-bold;
  }
  h3 {
    font-size: $header-4;
    font-weight: $font-weight-bold;
    color: $color-accent-dark;
  }

  h2.woocommerce-Reviews-title {
    margin-top: 0;
    // text-transform: uppercase;
    font-size: $font-size;
    margin-bottom: 2rem;
    color: $color-grey-1;
    font-weight: $font-weight-bold;
    span {
      color: $color-accent;
      font-weight: $font-weight-normal;
    }
  }
  p {
    iframe {
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
    }
  }
}

.woocommerce-tabs .tabs {
  margin-bottom: calc($font-size / 2);
  padding-left: 0;
  list-style: none;
  display: flex;
  gap: calc($font-size / 2);
  @media screen and (max-width: 575px) {
    display: flex;
    gap: calc($font-size / 2);
  }
  li {
  }

  li a {
    // @include tabs-title;
    display: block;
    position: relative;
    padding: 4px 8px;
    background-color: rgba($color: #000000, $alpha: 0.2);
    color: $color-grey-2;
    border-radius: 3px;
    @media screen and (max-width: 575px) {
      text-align: center;
    }
    @media screen and (max-width: 380px) {
      padding: 4px 6px !important;
      font-size: 0.75rem;
    }

    &:hover {
      background-color: $color-grey-4;
      color: $color-background;
    }
  }

  li.active a {
    color: $color-background;
    background-color: $color-grey-1;
    position: relative;
  }
  // .misha_custom_tab_tab a {
  //   background-color: $color-primary;
  //   color: $color-background;
  // }
  .woocommerce-Tabs-panel {
  }
}

.comment-form-email,
.comment-form-author {
  label {
    display: block;
  }
}

.wp-comment-cookies-consent {
  label {
    font-size: $font-size-smaller !important;
  }
}

.woocommerce-product-attributes {
  width: 100%;
  &-item {
    @media screen and (max-width: 575px) {
      font-size: 0.8rem;
    }
    &__label {
      background-color: $color-grey-6;
      width: 25%;
      text-align: right;
      padding-right: 1rem;
      @media screen and (max-width: 575px) {
        padding-right: 0;
        text-align: center;
      }
    }
    &__value {
      padding-left: 1rem;
      background-color: rgba(0, 0, 0, 0.05);
      @media screen and (max-width: 575px) {
        padding-left: 0;
        text-align: center;
      }
      p {
        margin: 0.5rem 0;
      }
    }
  }
}

// Product Gallery -------------------------------
.woocommerce-product-gallery {
  position: relative;
  margin-bottom: 1rem;

  &__trigger {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 10;
  }

  &__wrapper {
    min-width: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    // grid-auto-flow: column;
    // grid-auto-columns: minmax(0, 1fr);

    gap: calc($font-size-smaller / 4);
  }

  &__image {
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    aspect-ratio: 1 / 1;
    img {
      width: 100% !important;
      max-width: 100% !important;
      height: auto;
    }
    &:first-child {
      width: 100%;
      height: auto;
      display: flex;
      margin-right: 0;
      grid-column: 1 / -1;
      align-items: center;
      justify-content: center;
    }
  }
  img.wp-post-image {
    width: 100% !important;
    height: auto !important;
  }

  &__image--placeholder {
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    aspect-ratio: 1 / 1;
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100% !important;
      max-width: 100% !important;
      height: auto !important;
    }
  }

  &__trigger {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    background-color: white;
    border-radius: 50px;
    i {
      color: $color-dark;
      font-size: $header-3;
    }
  }
}

// Relacionados -------------------------------
.products.related > h2,
.products.upsells > h2 {
  // @include related-header;
  // border-bottom: 1px solid #666;
  margin-bottom: 1.2rem !important;
  font-size: 1.2rem;
}

section.related ul.products {
  display: grid;
  grid-template-columns: 1fr 1fr;

  li.product {
    // border-bottom: 1px solid $color-grey-4;);
    display: block;
    width: 100%;
    height: auto;
    clear: both;
    // text-align: center;
  }

  .attachment-woocommerce_thumbnail {
    float: left !important;
    width: 100% !important;
    height: auto;
    margin-right: 1rem;
  }

  .woocommerce-loop-product__link {
    width: 100%;
    align-items: inherit;
    display: block !important;
    // margin-bottom: 0;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    .vi_wpvs_loop_variation_form {
      display: flex;
      justify-content: center !important;
    }
  }

  .product__cat {
  }

  .woocommerce-loop-product__title {
    font-size: 0.9rem;
    width: auto;
    color: $color-dark;
    font-weight: $font-weight-medium;
  }

  .price {
  }
}

// Prodcut Reviews -------------------------------
.woocommerce-Reviews {
  .woocommerce-Reviews-title {
  }

  .woocommerce-noreviews {
  }
}

#review_form_wrapper {
}

#review_form {
  .comment-reply-title {
    font-size: $font-size-smaller;
    margin-bottom: 1rem;
    display: block;
  }

  .comment-notes {
  }
}

.product .star-rating span:before,
.star-rating::before {
  color: $color-primary !important;
  border-color: $color-primary !important;
}

.woocommerce-product-rating .woocommerce-review-link {
  font-size: $font-size-smaller;
  color: $color-grey-2;
}

// OFERTA -------------------------------
// .product {
//   position: relative;
//   .onsale {
//     background-color: $color-secondary;
//     display: inline-block;
//     position: absolute;
//     left: 1rem;
//     top: 1rem;
//     z-index: 10;
//     font-size: $font-size-small;
//     padding: 5px 10px;
//     border-radius: 50px;
//     color: $color-background;
//   }
// }

.stock.out-of-stock {
  display: none;
}

.notas {
  font-size: $font-size-small;
}
