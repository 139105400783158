.dfd-root {
}
.dfd-layer {
  //background-color: $color-primary-dark !important;
}

.dfd-searchbox {
  //border-radius: 25px;
}

.dfd-close-button {
  // color: $color-secondary;
  // caret-color: $color-secondary;
}

.dfd-aside {
  .dfd-facet {
    .dfd-facet-type-image {
      button.dfd-btn-image-filter {
        .dfd-filter-image {
          height: 5.25rem;
          img {
            border-radius: 8px;
          }
        }
        span {
          font-weight: $font-weight-medium;
        }
        &:hover {
          .dfd-filter-image::before {
            border-width: 2px;
            border-color: $color-secondary;
            border-radius: 12px;
          }
          span {
            font-weight: $font-weight-bold;
          }
        }
      }
    }
  }
}

//Grid de resultados
.dfd-results-grid,
.dfd-recommended-products {
  .dfd-card-type-product {
    padding: 0;
    border-radius: 0;
    border: none !important;
    .dfd-card-media {
      .dfd-card-thumbnail {
        border-radius: 7px;
        img {
          object-fit: cover;
        }
      }
    }
    .dfd-card-content {
      padding-top: $space-1;
      padding-bottom: $space-1;
      margin-top: 0;
      .dfd-card-title {
        font-weight: $font-weight-bold;
        color: $color-black;
        font-size: $font-size-big;
      }
      .dfd-card-row {
        align-items: flex-start;
        flex-direction: column;
        margin-top: 0;
      }
      .dfd-card-pricing {
        .dfd-card-price {
          color: $color-secondary;
          font-size: $font-size;
        }
      }
      .dfd-cart-add-button {
        background-color: $color-grey-5;
        border-color: $color-grey-5;
        color: $color-black;
        margin-left: 0;
        margin-top: $font-size;
        width: max-content;
        border-radius: 25px;
        padding: 4px 14px;
        height: auto;
        transition: $trans;
        svg {
          width: 20px;
          height: 20px;
          fill: $color-dark;
        }
        &::after {
          content: "Añadir al carrito";
          margin-left: calc($font-size / 3);
          font-weight: $font-weight-bold;
          font-size: $font-size-smaller;
          line-height: 1;
        }
        &.dfd-btn-done::after {
          content: "";
          border-bottom-color: $color-black;
          border-right-color: $color-black;
        }
        &.dfd-btn-loading::before {
          border: 2px solid $color-black;
          border-right-color: transparent;
        }
        &.dfd-btn-done,
        &.dfd-btn-loading {
          height: 30px;
        }
        &:hover {
          background-color: $color-grey-5 !important;
          border-color: $color-grey-5 !important;
          box-shadow: $box-shadow;
          transform: translateY(-2px);
          transition: $trans !important;
        }
      }
    }

    &:hover {
      border: none !important;
      box-shadow: none !important;
    }
  }
}

.dfd-branding {
  display: none;
  visibility: hidden;
}

// .woocommerce-product-search {
//   // border-radius: 25px;
//   // overflow: hidden;
//   display: flex;
//   flex-direction: row;
//   position: relative;
//   min-width: 300px;
// }

// .woocommerce-product-search input {
//   border-radius: 40px;
//   color: $color-dark;
//   font-size: $font-size-small;
//   // padding-top: calc($font-size);
//   // padding-bottom: calc($font-size);
//   padding-left: $font-size;
//   padding-right: $space-3;
//   width: 100%;
// }

// .woocommerce-product-search button {
//   position: absolute;
//   right: calc($font-size / 2);
//   top: calc($font-size / 2);
//   bottom: calc($font-size / 2);
//   padding-left: $font-size;
//   padding-right: $font-size;
// }

// .woocommerce-product-search button {
//   background-color: $color-accent;
//   color: $color-dark;
//   border: 1px solid $color-accent;
//   color: $color-white;
//   border-left: 0;
//   border-radius: 50px;
//   font-size: $font-size-small;
//   // &::before {
//   //   font-family: unicons-line;
//   //   font-style: normal;
//   //   font-weight: 500;
//   //   speak: none;
//   //   display: inline-block;
//   //   text-decoration: inherit;
//   //   font-variant: normal;
//   //   text-transform: none;
//   //   line-height: 1;
//   //   -webkit-font-smoothing: antialiased;
//   //   -moz-osx-font-smoothing: grayscale;
//   //   content: "\e99a";
//   //   font-size: $font-size-bigger;
//   //   padding: 0 $font-size 0 0.8rem;
//   //   margin-top: 3px;
//   // }
// }
