.woocommerce-notices-wrapper {
  display: block;
  padding-top: 1rem;
  margin-bottom: 3rem;
  @media screen and (max-width: 575px) {
    margin-bottom: 1rem;
  }

  &:empty {
    margin-bottom: 0;
    padding: 0;
  }
  .woocommerce-error {
    li {
      color: $color-error;
      font-weight: $font-weight-bold;
    }
  }
  .woocommerce-message {
    font-size: $font-size;
    font-weight: bold;
    overflow: hidden;
    display: block;
    text-align: left;
    background-color: rgb(255, 255, 223);
    color: $color-dark;
    border: 1px dashed #979d6f;
    border-radius: 5px;
    padding: $font-size;
    box-shadow: none;
    text-align: center;
    @media screen and (max-width: 575px) {
      font-size: $font-size-small;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: $font-size-small;
      .button {
        margin-top: calc($font-size / 2);
      }
    }
    a.restore-item {
      @media (max-width: 575px) {
        height: auto;
        display: inline;
      }
    }
  }
}

.woocommerce-NoticeGroup-checkout .woocommerce-error {
  li {
    color: rgb(136, 15, 15);
  }
}

.woocommerce-store-notice,
p.demo_store {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
  font-size: 0.9rem;
  padding: 1em 0;
  text-align: center;
  background: $color-primary;
  color: $color-dark;
  z-index: 99998;
  box-shadow: 0 1px 1em rgba(0, 0, 0, 0.2);
  display: none;
  text-wrap: balance;
  @media screen and (max-width: 767px) {
    font-size: 0.8rem;
  }
  a {
    color: $color-dark;
    text-decoration: underline;
  }
}

.woocommerce-store-notice__dismiss-link {
  font-weight: $font-weight-bold;
  margin-left: 10px;
}
