.wrapper-header {
  display: flex;
  flex-direction: column;
  z-index: 100;
  position: sticky;
  top: 0;
  &--sticky {
    transition: $trans;
    box-shadow: $box-shadow;
  }
}

.header {
  padding: $font-size 0 $font-size 0;
  background-color: $color-grey-1;
  width: 100%;
  transition: $trans;
  @media screen and (max-width: 991px) {
    padding-bottom: 0.75rem;
  }
  @media screen and (max-width: 767px) {
    padding: $font-size-smaller 0;
  }

  &--home {
  }

  &__content {
    display: grid;
    grid-template-columns: max-content max-content 1fr max-content;
    align-items: center;
    justify-content: flex-start;
    gap: $space-1;
    @media screen and (max-width: 767px) {
      gap: $font-size;
    }
  }

  &__logo {
    img {
      width: 150px;
      height: auto;
      transition: $trans;
      @media screen and (max-width: 991px) {
        width: 100px;
        transition: $trans;
      }
      @media screen and (max-width: 420px) {
        width: 100px;
        transition: $trans;
      }
    }
  }

  &__search {
    margin: 0 $space-1;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__woo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $space-1;
    @media screen and (max-width: 991px) {
      margin-left: auto;
    }
    @media screen and (max-width: 420px) {
      gap: $font-size;
    }
  }

  &__woo-item {
    a {
      color: $color-white;
      transition: $trans;
      &:hover {
        color: $color-secondary;
        transition: $trans;
      }
    }
  }

  &__woo-item--carrito {
    position: relative;
  }
  &__woo-item-group {
    display: flex;
    align-items: center;
    gap: $font-size;
    @media screen and (max-width: 767px) {
      gap: $font-size-small;
    }
  }
  &__woo-item-details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 0.85rem;
    strong {
      color: $color-grey-3;
      text-transform: uppercase;
    }
  }

  &__woo-item-total {
    position: absolute;
    top: -3px;
    right: -7px;
    background-color: $color-primary;
    color: $color-dark;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    width: 1rem;
    height: 1rem;
    font-weight: $font-weight-bold;
    transition: $trans;
    @media screen and (max-width: 767px) {
      top: -5px;
      right: -8px;
    }
    &--oculto {
      visibility: hidden;
      transition: $trans;
    }
  }

  i {
    color: $color-white;
    font-size: $header-3;
    transition: $trans;
    line-height: 1;
    &:hover {
      color: $color-primary;
      transition: $trans;
    }
    @media screen and (max-width: 420px) {
      font-size: $header-3;
    }
  }

  &__burger {
    // @media screen and (min-width: 768px) {
    //   display: none;
    // }
  }
}

.header-sub {
  background-color: $color-accent;
  width: 100%;
  @media screen and (max-width: 991px) {
    background-color: $color-grey-1;
  }
  &__nav {
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
  &__search {
    padding-bottom: calc($font-size * 0.5);
    @media screen and (min-width: 992px) {
      display: none;
    }
  }
}

.widget-search {
  .searchform {
    div {
      position: relative;
      input[type="text"] {
        border-radius: 50px;
        width: 300px;
      }
      input[type="submit"] {
        position: absolute;
        width: auto !important;
        top: 9px;
        // border-radius: $border-radius;
        border-radius: 50px;
        font-size: $font-size-small;
        right: $font-size-smaller;
        padding: 5px 10px;
        border: 0;
      }
    }
  }
}

.page-template-page-carrito,
.page-template-page-checkout {
  .header__woo-item--carrito {
    display: none;
  }
}

.c-list {
  position: fixed;
  width: 100%;
  top: 93px;
  z-index: 999;
  background-color: $color-primary-darker;
  padding: $font-size 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $space-1;
  transition: $trans;
  &--sticky {
    top: 76px;
    transition: $trans;
    box-shadow: $box-shadow;
  }

  &--home {
  }
  &--shop {
  }

  &__item {
  }

  &__link {
    color: $color-white;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    line-height: 1;
    &:hover {
      color: $color-secondary;
    }
  }

  &__link--active {
    color: $color-secondary;
    text-decoration: underline;
  }

  &__name {
  }
  &__name--pic {
  }
}

.header-checkout {
  padding: $font-size 0;
  background: $color-grey-1;
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    img {
      width: 150px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 100px;
      }
    }
  }

  &__help {
    display: flex;
    align-items: center;
    gap: $font-size;
    a {
      color: $color-white;
      font-size: $font-size-small;
      &:hover {
        color: $color-white;
      }
    }
  }
}
