.page-title {
  padding-top: $space-1;
  padding-bottom: $space-1;
  &__h {
    margin-bottom: 0rem;
    font-size: $header-1;
    font-weight: $font-weight-bolder;
    color: $color-dark;
    transition: $trans;
    @media screen and (max-width: 991px) {
      font-size: $header-1-res;
      transition: $trans;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-2;
      transition: $trans;
    }

    @media screen and (max-width: 375px) {
      font-size: $header-2-res;
      transition: $trans;
    }
    span {
      text-decoration: underline;
    }
  }
  &__h2 {
  }
  &__breadcrumbs {
    margin-bottom: calc($font-size / 2);
    font-size: $font-size-smaller;
    color: $color-dark;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    @media screen and (max-width: 575px) {
      font-size: $font-size-smaller;
      margin-bottom: 5px;
    }
    & > span {
      @media screen and (max-width: 767px) {
        overflow-x: scroll;
        width: 100%;
        display: flex;
        gap: 0.25rem;
      }
      span {
        white-space: nowrap;
      }
    }
    a {
      font-weight: $font-weight-normal;
      color: $color-grey-3;
      @media screen and (max-width: 767px) {
        font-weight: $font-weight-normal;
      }
    }
  }
}

.single-product {
  .page-title {
    padding-top: $font-size-smaller;
    padding-bottom: $font-size;
    @media screen and (max-width: 575px) {
      margin-bottom: 0;
      background-color: $color-background;
    }
  }
}
