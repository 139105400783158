.flex-categorias {
  padding: $space-2 0;
  &__items {
    display: flex;
    gap: $font-size-small;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__item {
    flex: 1;
    position: relative;
    border-radius: $border-radius-min;
    overflow: hidden;
  }

  &__picture {
    img {
      width: 100%;
      height: auto;
      aspect-ratio: 252 / 350;
      object-fit: cover;
      @media screen and (max-width: 767px) {
        aspect-ratio: initial;
        height: 200px;
      }
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    padding-bottom: $space-1;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  &__titulo {
    color: $color-white;
    font-weight: $font-weight-bold;
    font-size: $font-size;
    text-align: center;
    width: 100%;
    margin-bottom: 0;
    @media screen and (min-width: 768px) and (max-width: 991px) {
      font-size: $font-size-small;
    }
  }
}

.flex-logos {
  padding: $space-2 0;
  @media screen and (max-width: 767px) {
    padding-bottom: 0;
  }
  &__items {
    display: flex;
    gap: $space-1;
    justify-content: center;
    align-items: center;
    overflow-x: scroll;
    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
      gap: $space-1;
    }
    @media screen and (max-width: 991px) {
      gap: $font-size;
    }
    @media screen and (max-width: 575px) {
      gap: calc($font-size / 2) $font-size-smaller;
    }
  }

  &__item {
    svg,
    img {
      width: 100%;
      height: auto;
      max-width: 100px;
      max-height: 30px;
      @media screen and (max-width: 767px) {
        width: 100%;
        height: auto;
        max-width: 65px;
        max-height: 20px;
      }
    }
  }
}

.flex-catico {
  &__items {
    display: flex;
    gap: $font-size;
    justify-content: space-between;
    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
      gap: calc($font-size / 2);
    }
  }

  &__item {
    flex: 1;
    background-color: $color-grey-6;
    padding: $font-size;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px dashed $color-grey-5;
    @media screen and (max-width: 767px) {
      padding: $font-size-smaller;
    }
  }

  &__picture {
    margin-bottom: $font-size;
    img {
      height: 50px;
      width: auto;
    }
  }

  &__titulo {
    font-size: $font-size;
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
  }
}

.card-financiacion {
  padding: $space-1 0;
  &__card {
    padding: $space-1;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: $space-1;
    background: $color-primary-light;
    border-radius: $border-radius;
    border: 1px dahsed darken($color: $color-primary-light, $amount: 50%);
    @media screen and (max-width: 767px) {
      gap: calc($font-size / 2);
      padding: $font-size;
    }
  }

  &__logo {
    img {
      width: 80px;
      height: auto;
    }
  }

  &__descripcion {
    display: flex;
    flex-wrap: wrap;
    gap: $font-size;
    @media screen and (max-width: 767px) {
      font-size: $font-size-smaller;
      gap: calc($font-size / 3);
      text-align: center;
      justify-content: center;
      text-wrap: balance;
    }
  }

  &__link {
    color: $color-primary-darker;
    text-decoration: underline;
    font-weight: bold;
  }
}
