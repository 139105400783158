.login {
  padding: $space-4 0 $space-5 0;
  position: relative;
  background: $color-grey-6;
  @media screen and (max-width: 767px) {
    padding-top: $space-1;
  }
  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.2);
    @media screen and (max-width: 767px) {
      background: rgba($color: #000000, $alpha: 0.5);
    }
  }
  &__container {
    position: relative;
  }
}

.page-template-page-login.logged-out .bg-login {
  background: url("../assets/site/bg-login.jpeg") center center;
  background-color: $color-grey-6;
  background-size: cover;
}

.page-template-page-login.logged-in .login__overlay {
  background: none;
}

.logged-out.page-template-page-login .woocommerce > h2 {
  display: none;
}

#customer_login{
  h2{
    color: $color-white;
    text-align: center;
    font-weight: $font-weight-bold;
  }
}

.woocommerce-form-login, .woocommerce-form-register {
  padding: 2rem;
  background-color: $color-background;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  p:nth-last-child(1){
    margin-bottom: 0;
  }
}
.woocommerce-form-login {
  max-width: 400px;
  margin: 0 auto;
}

// Lost Password
.page-template-page-login.woocommerce-lost-password{
  .woocommerce-ResetPassword{
    padding: 2rem;
    background-color: $color-background;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    max-width: 600px;
    margin: 0 auto;
    p:nth-last-child(1){
      margin-bottom: 0;
    }
    .form-row{
      width: 100%;
      margin-right: 0;
    }
  }

  &.reset-link-enviado{
    .login{

    }
    .login__container .woocommerce{
      padding: 2rem;
      background-color: $color-background;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      max-width: 600px;
      margin: 0 auto;
      p:nth-last-child(1){
        margin-bottom: 0;
      }
    }
    .woocommerce-message{
      margin-bottom: $font-size;
    }
    p{
      font-size: $font-size-small;
    }
  }

}

.woocommerce-form-row,
.woocommerce-form-login {
  label {
    display: block;
    margin-bottom: 8px;
    font-size: 0.9rem;
  }

  input {
    display: block;
    width: 100%;
  }

  &__rememberme {
    padding-bottom: 1rem !important;
  }

  &__submit {
    display: block;
    // width: 100%;
  }

  .lost_password {
    font-size: $font-size-smaller;
    text-align: right;
  }
}

.page-template-page-login.logged-in .woocommerce:not(.widget-search, .off-carrito) {
  display: grid;
  grid-template-columns: 3fr 9fr 1fr;
  grid-gap: 3rem;

  @media (max-width: 991px) {
    display: initial;
  }
}

.woocommerce-MyAccount-navigation {
  ul {
    list-style: none;
    padding-left: 0;
    @media (max-width: 991px) {
      display: flex;
      padding: 0;
      background-color: initial;
      margin-top: 0;
      flex-wrap: wrap;
      
    }
    @media screen and (max-width: 767px) {
      gap: calc($font-size / 2);
    }
  }

  li {
    border-bottom: 1px solid $color-grey-5;
    @media screen and (max-width: 991px) {
      margin-bottom: 3px;
      border: 0;
    }
    a {
      padding: calc($font-size / 2) 0 !important;
      display: inline-block;
      @media (max-width: 991px) {
        padding: 5px 1rem;
        background-color: $color-grey-4;
        border-radius: 50px;
        color: $color-background;
        display: inline-flex;
      }

      @media (max-width: 767px) {
        padding: 5px .8rem !important;
        font-size: $font-size-smaller;
        color: $color-grey-3;
        color: $color-background;
      }
    }
  }


  li.is-active a {
    color: $color-dark;
    font-weight: $font-weight-bold;
    @media (max-width: 991px) {
      font-size: $font-size-smaller;
      color: $color-background;
      background-color: $color-secondary;
    }
  }
}

.woocommerce-MyAccount-content {
  .woocommerce-table--order-details {
    display: block;
  }

  .woocommerce-Message--info {
    display: flex;
    flex-direction: column;
  }

  .woocommerce-Message .button {
    display: inline-block;
    width: max-content;
    margin-bottom: 1rem;
  }
}

.woocommerce-EditAccountForm {
  span em {
    font-size: $font-size-smaller;
  }
}

.account-orders-table {
  font-size: 0.9rem;
  border-collapse: collapse;
  thead {
    @media (max-width: 767px) {
      display: none;
    }
  }
  tbody {
    @media (max-width: 767px) {
      font-size: $font-size-smaller;
    }
    tr {
      @media (max-width: 767px) {
        display: block;
        display: flex;
        flex-direction: column;
        background-color: $color-background;
        margin-bottom: 1rem;
        padding: 1rem;
        border-radius: $border-radius;
      }
    }
    td {
      text-align: center;
      padding: 10px 5px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-left: 0;
      border-right: 0;
      @media (max-width: 767px) {
        text-align: right;
        padding: 8px 5px;
      }
    }

    .view {
      padding: 6px 1rem;
      display: block;
    }
  }

  .woocommerce-orders-table__cell-order-status {
    font-weight: bold;
  }
  .woocommerce-orders-table__cell-order-actions .view {
    display: inline-block;
  }
}

div[data-title="Cancelado"] {
  color: rgb(118, 14, 14);
}

.woocommerce-MyAccount-content {
  > p {
    a {
      color: $color-accent-dark;
    }
  }
  .woocommerce-EditAccountForm{
    .form-row-first{
      margin-right: 3%;
    }
    .form-row{
      input{
        width: 100%;
        border-radius: 4px;
      }
    }
  }
  h3, fieldset legend{
    font-size: $header-3;
    font-weight: $font-weight-bold;
  }

  fieldset{
    margin-top: $space-2;
  }

  .woocommerce-input-wrapper{
    display: flex;
    width: 100%;
    input{
      width: 100%;
      border-radius: 4px;
    }
  }
}


// Puntos
.ywpar-wrapper {
  .ywpar_myaccount_entry_info {
    .ywpar_summary_badge {
      background: $color-white;
    }
  }
  #ywpar_tabs {
    .ywpar_tabcontent {
      background: $color-white;
    }
  }
}


// Addreses

.woocommerce-Addresses {
  margin-bottom: $space-1;
  .woocommerce-Address-title {
    margin-bottom: $font-size;
    h3 {
      font-size: $header-4;
    }
    a {
      color: $color-accent;
      text-decoration: underline;
      font-size: $font-size-small;
    }
  }
  .woocommerce-Address {
    padding: $space-1;
    // border: 1px dashed $color-grey-4;
    background: $color-white;
    border-radius: $border-radius-min;
    address {
      font-size: $font-size-small;
    }
  }
}

