.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  &__wrapper {
    height: 400px;
    background-color: #ebeae8;
    position: relative;
    transition: $trans;
    overflow: hidden;
    width: 100%;
    @media screen and (max-width: 575px) {
      height: 350px;
      transition: $trans;
    }
  }

  &__bg {
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    z-index: 1;
    transition: $trans;

    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__container {
    position: relative;
    z-index: 2;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    transition: $trans;
    @media screen and (max-width: 575px) {
      align-items: flex-end;
      transition: $trans;
    }
  }
  &__content {
    text-align: center;
    transition: $trans;

    @media screen and (min-width: 768px) {
      width: 75%;
      transition: $trans;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      transition: $trans;
      align-items: flex-start;
      text-align: left;
    }
    @media screen and (max-width: 575px) {
      margin-top: 0;
      margin-bottom: $space-2;
    }
  }

  &__pretitulo {
    color: $color-white;
    font-weight: $font-weight-bold;
    margin-bottom: 0;
    font-size: $header-3;
    transition: $trans;

    @media screen and (max-width: 991px) {
      font-size: $header-3-res;
      transition: $trans;
    }
  }

  &__titulo {
    color: $color-white;
    font-weight: $font-weight-bold;
    font-size: $display-3;
    transition: $trans;

    @media screen and (max-width: 1199px) {
      font-size: $header-1;
      transition: $trans;
    }
    @media screen and (max-width: 991px) {
      font-size: $header-1-res;
      transition: $trans;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-3;
      transition: $trans;
    }
  }

  &__subtitulo {
    color: $color-white;
    font-weight: $font-weight-medium;
    font-size: $header-2;
    transition: $trans;
    margin-bottom: $font-size;
    @media screen and (max-width: 1199px) {
      font-size: $header-2;
      transition: $trans;
    }
    @media screen and (max-width: 991px) {
      font-size: $header-2-res;
      transition: $trans;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-4;
      transition: $trans;
    }
  }

  &__tips {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $font-size;
    @media screen and (max-width: 767px) {
      justify-content: flex-start;
    }
  }

  &__tip {
    background-color: $color-primary;
    color: $color-dark;
    font-weight: $font-weight-medium;
    padding: 4px 12px;
    border-radius: $border-radius;
    @media screen and (max-width: 767px) {
      font-size: $font-size-smaller;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $font-size;
  }
}

.envio-rapido {
  padding: 1rem 0;
  &__content {
    text-align: center;
    strong {
      transition: $trans;

      @media screen and (max-width: 767px) {
        transition: $trans;

        display: block;
        width: 100%;
      }
    }
  }
}
