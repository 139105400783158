.widget {
  &__title {
    margin-bottom: 1.3rem;
    font-size: 1.2rem;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 575px) {
      margin-bottom: 0.6rem;
      font-size: 1rem;
    }
  }
}

.widget--footer {
  margin-bottom: 2rem;
  .product-categories,
  .menu {
    list-style: none;
    padding-left: 0rem;
    li {
      a {
        font-size: $font-size-small;
        color: $color-background;
        &:hover {
          color: rgba(200, 200, 200, 1);
        }
        @media screen and (max-width: 767px) {
          padding: 5px 10px;
          display: block;
        }
      }
    }
  }
  .textwidget {
    a {
      color: $color-white;
    }
  }
}

.widget--page {
  @media screen and (max-width: 575px) {
    margin-bottom: 2rem !important;
  }
  .product-categories,
  .menu {
    list-style: none;
    padding-left: 0rem;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      @media screen and (max-width: 767px) {
        margin-right: 8px;
        margin-bottom: 8px;
      }
      a {
        @media screen and (max-width: 767px) {
          padding: 4px 8px;
          background-color: $color-grey-4;
          color: $color-background;
          display: inline-flex;
          border-radius: 2px;
        }
      }
    }
    li.current-menu-item {
      a {
        font-weight: bold;
      }
    }
  }
}

.widget--legal {
  margin-bottom: $space-2;
  &:nth-last-child(1) {
    margin-bottom: 0;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 0.5rem;
  }
  .widget__title {
    margin-bottom: $font-size;
    @media screen and (max-width: 767px) {
      display: none;
      visibility: hidden;
    }
  }
  ul.menu {
    list-style: none;
    padding-left: 0rem;
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }
    li {
      a {
        @media screen and (max-width: 767px) {
          padding: 4px 8px;
          background-color: $color-primary-dark;
          color: $color-white;
          display: inline-flex;
          border-radius: 4px;
        }
      }
    }
    li.current-menu-item {
      a {
        text-decoration: underline;
      }
    }
  }
}

.zoom-social-icons-list {
  @media screen and (max-width: 575px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.last-seen {
  padding: $space-3 0 $space-1 0;
  &--oculto {
    visibility: hidden;
    display: none;
  }
  &__notification {
    visibility: hidden;
    display: none;

    &--visible {
      padding: $space-3 0;

      visibility: visible;
      display: flex;
    }
  }
  &__notification-content {
    width: 100%;
    padding: $space-1;
    background-color: $color-grey-6;
    border-radius: $border-radius;
    border: 1px dashed $color-grey-4;
    color: $color-dark;
  }
}

.last-seen-products {
  &__wrapper-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: $space-1;
  }
  ul.product_list_widget {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: $space-3 $space-1;
    grid-auto-rows: 1fr;
    @media (min-width: 768px) and (max-width: 991px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
    }

    @media (max-width: 480px) {
      grid-gap: 1rem;
    }
    li {
      position: relative;
      background-color: $color-background;
      border-radius: $border-radius;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 0.25rem;
      height: 100%;
      a {
        background-color: $color-background;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        border-radius: $border-radius;
        text-align: center;
        &:hover {
          .product-title {
            color: $color-primary;
          }
        }
        img {
          width: 100% !important;
          height: auto;
          margin-bottom: $font-size-smaller;
          border-radius: 7px;
          aspect-ratio: 1;
          object-fit: contain;
          border: 1px solid rgba($color: #000000, $alpha: 0.1);
          @media (max-width: 575px) {
            margin-bottom: 0.8rem !important;
          }
        }
        .product-title {
          font-size: $font-size-smaller;
          margin-bottom: 0.5rem;
          font-weight: $font-weight-medium;
          width: 100%;
          color: $color-grey-1;
          text-align: left;
          @media (max-width: 575px) {
            width: 100%;
            text-align: center;
          }
        }
      }

      .product-price-container {
        display: none;
        visibility: hidden;
      }

      .woocommerce-Price-amount {
        // color: $color-dark;
        // font-size: $font-size-smaller;
        // font-weight: $font-weight-bold;
        // margin-top: auto;
      }

      ins,
      del {
        text-decoration: none;
        display: inline-flex;
        width: max-content;
      }
      del .woocommerce-Price-amount {
        color: $color-grey-4;
        text-decoration: line-through;
      }
      ins .woocommerce-Price-amount {
        color: rgb(193, 0, 0);
        font-weight: $font-weight-medium;
      }
      .woocommerce-Price-currencySymbol {
        font-size: calc($font-size / 1.4);
      }
    }
  }
}

.woocommerce-product-search {
  display: flex;
  flex-direction: row;
  position: relative;
  min-width: 300px;
  margin-bottom: 0;
}

.woocommerce-product-search input {
  border-radius: 40px;
  color: $color-dark;
  font-size: $font-size-small;
  padding-top: calc($font-size);
  padding-bottom: calc($font-size);
  padding-left: $font-size;
  padding-right: $space-3;
  width: 100%;
  @media screen and (max-width: 991px) {
    padding-top: calc($font-size * 0.6);
    padding-bottom: calc($font-size * 0.6);
    padding-left: $font-size;
    padding-right: 6rem;
  }
}

.woocommerce-product-search button {
  position: absolute;
  right: calc($font-size / 2);
  top: calc($font-size / 2);
  bottom: calc($font-size / 2);
  padding-left: $font-size;
  padding-right: $font-size;
  background-color: $color-accent;
  color: $color-dark;
  border: 1px solid $color-accent;
  color: $color-white;
  border-left: 0;
  border-radius: 50px;
  font-size: $font-size-small;
  line-height: 1;
}

.page-404 {
  padding: $space-4 0;
  &__content {
    max-width: 60%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  &__cover {
    margin-bottom: $space-1;
    img {
      width: 300px;
      max-width: 300px;
      height: auto;
      @media screen and (max-width: 767px) {
        max-width: 200px;
      }
    }
  }

  &__article {
  }

  &__title {
    font-size: $header-3;
    font-weight: $font-weight-bold;
  }

  &__subtitle {
  }
  &__actions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: $font-size;
    margin-top: $space-2;
  }
}
