.woocommerce-tabs #tab-description {
  .ficha {
    background-color: rgba($color: #000000, $alpha: 0.02);
    border-radius: $font-size;
    padding: $space-2;
    margin-bottom: $space-1;
    &__codigo {
    }

    &__item {
      border-bottom: solid 1px $color-grey-5;
      padding-bottom: $font-size;
      margin-bottom: $font-size;
      &:last-child {
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    &__titulo {
      font-size: $font-size;
      text-transform: uppercase;
      color: $color-secondary;
      letter-spacing: 1px;
      margin-bottom: calc($font-size-smaller/2);
    }

    &__data {
      font-size: $font-size-small;
      color: $color-grey-3;
      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
          margin-bottom: $font-size-smaller;
          padding-left: $space-1;
          position: relative;
          font-size: $font-size-small;
          &::before {
            position: absolute;
            left: 0;
            top: 3px;
            display: inline-block;
            content: "";
            vertical-align: -0.125em;
            background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z' clip-rule='evenodd'/></svg>");
            background-repeat: no-repeat;
            background-size: 1rem 1rem;
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
  }
}
