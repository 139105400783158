.s-featured {
  display: flex;
  flex-direction: column;
  gap: $space-4;

  &__card {
    width: 100%;
    display: flex;
    align-items: flex-start;
    //gap: $space-4;
    @media screen and (max-width: 991px) {
      gap: $space-2;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: $space-1;
      gap: $font-size;
    }

    &:nth-child(even) {
      @media screen and (min-width: 768px) {
        flex-direction: row-reverse;
      }
    }
  }

  &__card-img {
    display: block;
    background-color: $color-white;
    border: $border-radius;
    box-shadow: $box-shadow-small;
    border-radius: $border-radius;
    overflow: hidden;
    flex: 0 0 55%;
    @media screen and (max-width: 991px) {
      flex: 0 0 50%;
    }
    @media screen and (max-width: 810px) {
      margin-bottom: $space-01;
    }
    img {
      aspect-ratio: 16 / 9;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 $space-2;
    @media screen and (max-width: 991px) {
      padding: 0;
    }
    &--center {
      align-items: center;
      text-align: center;
    }
  }

  &__card-pretitle {
    color: $color-primary;
    text-transform: uppercase;
    font-size: $font-size-big;
    font-weight: $font-weight-bold;
    margin-bottom: $font-size;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &__card-title {
    font-family: $typo-secondary;
    font-size: $header-1;
    font-weight: $font-weight-bold;
    line-height: 1;
    margin-bottom: $font-size;
    color: $color-secondary;
    margin-bottom: $space-1;
    @media screen and (max-width: 991px) {
      font-size: $header-1-res;
      transition: $trans;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-2;
      transition: $trans;
    }

    @media screen and (max-width: 375px) {
      font-size: $header-2-res;
      transition: $trans;
    }
    a {
      color: $color-secondary;
      &:hover {
        color: $color-secondary;
      }
    }
  }

  &__card-description {
    color: $color-text-body;
    line-height: 1.5;
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      li {
        position: relative;
        padding-left: $space-1;
        &::before {
          position: absolute;
          left: 0;
          top: 3px;
          display: inline-block;
          content: "";
          color: $color-text-body;
          vertical-align: -0.125em;
          background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z' clip-rule='evenodd'/></svg>");
          background-repeat: no-repeat;
          background-size: 1rem 1rem;
          width: 1rem;
          height: 1rem;
          // filter: brightness(0) invert(1);
        }
      }
    }
  }

  &__card-actions {
    margin-top: $space-01;
  }
}
