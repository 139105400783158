.offcanvas {
  &-body {
    background-color: $color-white;
    padding-top: 0.5rem;
  }

  &__close {
    display: flex;
    margin-left: auto;
    border: none;
    background: none;
    font-size: 2rem;
    line-height: 0;

    i {
      color: $color-dark;
      &:hover {
        color: $color-primary-dark;
      }
    }
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    margin-top: 1.4rem;
    align-items: flex-start;

    @media screen and (max-width: 575px) {
      margin-top: 0;
      padding-top: 0;
    }
  }

  &__menu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }
  &__nav {
    justify-content: flex-start;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 1rem;
    li.menu-item {
      // margin-bottom: 0.6rem;
      // @media screen and (max-width: 575px) {
      //   margin-bottom: 0.3rem;
      // }
      & > a {
        font-size: $header-5;
        font-weight: $font-weight-bolder;
        text-decoration: none;
        color: $color-dark;
        &:hover {
          color: $color-primary-dark;
        }
        // @media screen and (max-width: 767px) {
        //   font-size: $header-3;
        // }
      }
      ul.sub-menu {
        list-style: none;
        display: flex;
        justify-content: center;
        padding-left: 0;
        flex-wrap: wrap;
        li {
          margin: 0 5px 5px 0px;
          a {
            background-color: $color-dark;
            color: $color-primary !important;
            font-size: 1.5rem;
            padding: 4px 8px;
            border-radius: $border-radius-min;
            display: inline-block;
            font-weight: $font-weight-bold;
            transition: $trans;
            &:hover {
              background-color: $color-dark !important;
              color: $color-primary !important;
              transition: $trans;
            }
            @media screen and (max-width: 767px) {
              font-size: 1.25rem;
              transition: $trans;
            }
            @media screen and (max-width: 480px) {
              font-size: 1.125rem;
              transition: $trans;
            }
          }
        }
      }
    }
  }

  &__search {
    margin-top: 1.4rem;
  }

  &__logo {
    display: flex;
    justify-content: flex-start;
    margin-top: $space-1;
    img {
      width: 180px;

      transition: $trans;
      @media screen and (max-width: 575px) {
        width: 160px;
        transition: $trans;
      }
      @media screen and (max-width: 420px) {
        width: 150px;
        transition: $trans;
      }
    }
  }
  .product-categories {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > li.cat-item {
      & > a {
        display: inline-block;
        font-size: $header-4;
        color: $color-white;
        font-weight: $font-weight-bold;
        &:hover {
          color: $color-secondary;
        }
      }
      .children {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: calc($font-size / 2);
        .cat-item {
          a {
            padding: 4px 10px;
            display: inline-block;
            border-radius: 50px;
            background-color: $color-white;
            color: $color-dark;
            font-weight: $font-weight-bold;
          }
        }
      }
    }
  }
}

.filtrosOff {
  height: 100vh !important;
  &__header {
    background-color: $color-grey-6;
  }

  &__title {
    margin-bottom: 0;
    font-size: $header-5;
    font-weight: $font-weight-bold;
    color: $color-dark;
  }
  &__close {
    display: flex;
    margin-left: auto;
    border: none;
    background: none;
    font-size: 2rem;
    line-height: 0;
    i {
      color: $color-dark;
      &:hover {
        color: $color-primary-dark;
      }
    }
  }
}

.ubermenu-main {
  z-index: 99 !important;
  transition: $trans !important;
  overflow: hidden;
  padding: 0 !important;
  @media screen and (max-width: 1099px) {
    display: none !important;
    transition: $trans !important;
  }

  .ubermenu-nav {
    display: flex;
    gap: calc($font-size / 2);
    justify-content: flex-start;

    // ENLACES RIGHT
    .ubermenu-enlace-contacto {
      margin-left: auto;
    }
    .ubermenu-enlace-promo {
      background-color: $color-accent-dark;
    }

    // DROPDOWN MENU
    .ubermenu-enlace-todos.ubermenu-item.ubermenu-has-submenu-drop {
      & > a.ubermenu-target {
        background-color: $color-grey-1 !important;
        color: $color-white !important;
        pointer-events: none !important;
        padding: 16px 32px 16px 24px !important;
        i {
          pointer-events: none !important;
        }
        .ubermenu-target-text {
          color: $color-white !important;
        }
      }
      .ubermenu-tab {
        & > .ubermenu-target {
          padding: 9px 32px 9px 16px !important;
          .ubermenu-target-title {
            font-weight: $font-weight-medium;
          }
        }
      }
    }

    // TODOS LOS ITEMS DE MENÚ

    & > .ubermenu-item.ubermenu-item-level-0 {
      & > .ubermenu-target {
        padding: 16px 16px !important;
        line-height: 1;

        .ubermenu-target-text {
          font-size: $font-size !important;
          text-transform: initial;
          color: $color-white;
          font-weight: $font-weight-medium;
        }
        &:hover {
          .ubermenu-sub-indicator {
            color: $color-white !important;
          }
          .ubermenu-target-text {
            color: $color-white !important;
          }
        }
        @media screen and (max-width: 1249px) {
          padding-left: calc($font-size * 0.75) !important;
          padding-right: calc($font-size * 0.75) !important;
        }
      }
      &.ubermenu-current-menu-item,
      &.ubermenu-current-menu-parent {
        & > .ubermenu-target .ubermenu-target-text {
          color: $color-white !important;
        }
      }

      .ubermenu-tab-content-panel {
        .ubermenu-target {
          padding-top: 7px !important;
          padding-bottom: 7px !important;
          font-weight: $font-weight-normal !important;
          padding: 8px 32px 8px 24px !important;
          &:hover {
            background-color: $color-accent-light;
            .ubermenu-target-text {
              color: $color-dark !important;
            }
          }
          .ubermenu-target-text {
            font-size: $font-size-small !important;
          }
        }
      }

      // drowpdown flyout
      &.ubermenu-has-submenu-flyout {
        .ubermenu-target {
          padding-right: $space-1 !important;
          .ubermenu-sub-indicator {
            color: $color-white;
          }
        }
        .ubermenu-submenu {
          background-color: $color-white !important;
          border-radius: $border-radius-min !important;
          padding: calc($font-size-smaller / 3) !important;
          .ubermenu-item {
            .ubermenu-target {
              border-radius: $border-radius-min !important;
              &:hover {
                background: $color-accent-light;
              }
              .ubermenu-target-title {
                font-size: $font-size !important;
                font-weight: $font-weight-medium;
              }
            }
          }
        }

        .ubermenu-tab > .ubermenu-target {
          &:hover {
            background-color: $color-accent-light;
          }
          .ubermenu-target-text {
            font-size: $font-size-small !important;
            color: $color-dark !important;
          }
        }

        .ubermenu-highlight {
          .ubermenu-target-text {
            font-size: $font-size !important;
            font-weight: $font-weight-bold !important;
            margin-bottom: calc($font-size * 0.5);
          }
          &:hover {
            .ubermenu-target-text {
              color: $color-dark !important;
            }
          }
        }
      }
    }
  }
}

// Header categories --------------------
.categorias-nav {
  margin-bottom: 3rem;
  @media screen and (max-width: 767px) {
    margin-bottom: 1.5rem;
  }
  &__items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: $font-size-smaller;
    @media screen and (max-width: 991px) {
      gap: calc($font-size / 2);
    }
  }
  &__item {
    text-align: center;
    display: flex;
    gap: $font-size;
    padding: calc($font-size / 3);
    border: 2px solid $color-grey-5;
    border-radius: 50px;
    overflow: hidden;
    background-color: $color-white;
    &:hover {
      border-color: $color-secondary;
    }
    @media screen and (max-width: 767px) {
      gap: calc($font-size * 0.5);
    }
  }
  &__figure {
    display: inline-block;
    overflow: hidden;
    margin: 0;
    @media screen and (max-width: 991px) {
      visibility: hidden;
      display: none;
    }
  }
  &__img {
    width: 30px;
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 50px;
    border: 1px solid $color-grey-4;
    @media screen and (max-width: 575px) {
      width: 80px;
      height: 80px;
    }
  }

  &__icono {
    background-color: $color-primary;
    aspect-ratio: 1;
    width: 26px;
    height: auto;
    min-width: 26px;
    padding: 5px;
    border-radius: 50px;
    border: 1px solid $color-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__content {
    text-align: left;
    padding-right: $font-size-smaller;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    span {
      font-size: $font-size-small;
    }
    @media screen and (max-width: 991px) {
      width: 100%;
      align-items: center;
      text-align: center;
      padding: 0;
    }
  }
  &__title {
    font-size: $font-size;
    font-weight: $font-weight-bold;
    margin-bottom: 0;
    @media screen and (max-width: 575px) {
      font-size: $font-size-small;
      // font-weight: $font-weight-bold;
    }
  }
}

.nav-legal {
  &__menu {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: calc($font-size * 0.25) $font-size;
    margin-bottom: $space-1;
    @media screen and (max-width: 991px) {
      margin-bottom: 0;
      justify-content: center;
      text-align: center;
    }
    li.menu-item {
      font-size: $font-size-small;
    }
  }
}
