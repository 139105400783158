ul.slick-dots {
  bottom: -26px;
  li {
    margin: 0 0.1rem;
  }
  li button::before,
  li.slick-active button::before {
    color: $color-slick-dots;
    font-size: 2rem;
  }
  li button::before {
    opacity: 0.5;
  }

  li.slick-active button::before {
    color: $color-slick-dots;
    opacity: 1;
  }
}

.slick-track {
  padding-bottom: $space-1;
}

ul.products .slick-list {
  padding: 0 100px;
  @media screen and (max-width: 1399px) {
    padding: 0 200px;
  }
  @media screen and (max-width: 1199px) {
    padding: 0 60px;
  }
  @media screen and (max-width: 991px) {
    padding: 0 260px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 120px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 80px;
  }
  @media screen and (max-width: 540px) {
    padding: 0 10px;
  }
}
