.legal {
  padding: $space-3 0;

  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: $space-2;
    @media screen and (max-width: 1199px) {
      grid-template-columns: 1fr 3fr;
    }
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: $space-2;
    }
  }

  &__sidebar {
  }
  &__info {
  }

  .transporte-logo{
    margin-top: 0;
    margin-bottom: $space-2;
    img{
      width: 200px;
      height: auto;
    }
  }
}
