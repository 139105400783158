// borrar historial -------------------

.btn-borrar-historial {
  font-size: $font-size-smaller;
  background: $color-grey-2;
  color: $color-white;
  border-radius: 50px;
  padding: 2px 10px;
}

// wc pos -----------------------------

.wc-block-components-checkout-place-order-button,
.wc-block-components-button {
  background-color: $color-secondary;
  border-color: $color-secondary;
  color: $color-dark;
  border-radius: 50px;
  &:hover {
    background: $color-secondary-dark;
    color: $color-white;
  }
}

// wc pos -----------------------------

.submit,
.button {
  @include input-buttons;
  background-color: $color-accent;
  color: $color-white;
  border-radius: 50px !important;
  &:hover {
    @include input-buttons-hover;
    background-color: darken($color-accent, 10%);
    color: $color-white;
  }
  &.disabled {
    background-color: rgba($color-black, $alpha: 0.1);
    color: $color-grey-4;
    border: 0;
    cursor: not-allowed;
    &:hover {
      background-color: rgba($color-black, $alpha: 0.1) !important;
      color: $color-grey-4 !important;
      transform: initial !important;
    }
  }
  &[aria-disabled="true"] {
    background-color: $color-grey-4;
    opacity: 0.4;
    cursor: not-allowed;
    &:hover {
      background-color: $color-grey-4;
    }
  }
}

.button.cancel {
  background-color: $color-grey-4;
  color: $color-white;
  &:hover {
    background-color: $color-grey-3;
    color: $color-white;
  }
}

.checkout-button,
.single_add_to_cart_button,
.place-order button.button,
#submit_bizum_payment_form,
#submit_redsys_payment_form {
  padding: 1.1rem 2rem 1rem !important;
  font-size: 1.1rem !important;
  background-color: $color-accent;
  color: $color-white;
  @media (max-width: 575px) {
    font-size: 0.9rem !important;
    padding: 0.8rem 3rem !important;
  }
  &:hover {
    background-color: darken($color-accent, 10%);
    color: $color-white;
  }
}

.single_add_to_cart_button {
  &::before {
    // font-family: 'WooCommerce';
    // content: "\e01d";
    // font-weight: 300;
    // margin-right: 1rem;
    // font-size: 1.3rem;
    // display: inline-block;
    // content: '';
    // background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z' clip-rule='evenodd'/></svg>");
    // background-repeat: no-repeat;
    // background-size: 1rem 1rem;
    // @media screen and (max-width: 575px) {
    //   font-size: 1rem;
    //   margin-right: 0.6rem;
    // }
  }
}

form.cart button[type="submit"]:not(#qib_id):not(_):not(_) {
  height: auto !important;
}

li.product {
  .button {
    padding: 4px 14px;
    background-color: $color-accent;
    border-color: $color-accent;
    color: $color-white;
    background-color: $color-grey-6;
    border-color: $color-grey-5;
    color: $color-dark;
    border-width: 1px !important;
    border-radius: 20px;
    border-style: solid;
    width: fit-content;
    font-size: $font-size-smaller;
    line-height: 1;
    transition: $trans;
    &::before {
      font-family: unicons-line;
      font-style: normal;
      font-weight: 400;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      content: "\e889";
      margin-right: calc($font-size / 3);
      font-size: $font-size-bigger;
    }
    .bi {
      margin-right: 5px;
      margin-bottom: 5px;
      transition: $trans;
    }
    &:hover {
      border-color: $color-accent;
      transition: $trans;
    }
    &.vi_wpvs_loop_variation_no_pointer {
      opacity: 0.3;
    }
  }
}

.woocommerce-notices-wrapper {
  .woocommerce-message {
    a.button {
      display: inline-block;
      margin-right: 2rem;
      @media (max-width: 575px) {
        float: left;
        display: inline-block;
        padding: 0.85rem 1rem;
        height: auto;
        margin-bottom: 0.5rem;
        width: 230px;
        margin-right: 1rem;
      }
    }
  }
}

button.disabled,
button:disabled,
input[type="button"].disabled,
input[type="button"]:disabled,
input[type="reset"].disabled,
input[type="reset"]:disabled,
input[type="submit"].disabled,
input[type="submit"]:disabled,
.button.disabled,
.button:disabled,
.wc-block-grid__products
  .wc-block-grid__product
  .wp-block-button__link.disabled,
.wc-block-grid__products
  .wc-block-grid__product
  .wp-block-button__link:disabled,
.added_to_cart.disabled,
.added_to_cart:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed;
}
