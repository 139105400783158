@font-face {
  font-family: 'DM Sans';
  src: url('../../dist/fonts/dm-sans/DMSans-Italic.eot');
  src: url('../../dist/fonts/dm-sans/DMSans-Italic.eot?#iefix') format('embedded-opentype'),
      url('../../dist/fonts/dm-sans/DMSans-Italic.woff2') format('woff2'),
      url('../../dist/fonts/dm-sans/DMSans-Italic.woff') format('woff'),
      url('../../dist/fonts/dm-sans/DMSans-Italic.ttf') format('truetype'),
      url('../../dist/fonts/dm-sans/DMSans-Italic.svg#DMSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../../dist/fonts/dm-sans/DMSans-BoldItalic.eot');
  src: url('../../dist/fonts/dm-sans/DMSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../../dist/fonts/dm-sans/DMSans-BoldItalic.woff2') format('woff2'),
      url('../../dist/fonts/dm-sans/DMSans-BoldItalic.woff') format('woff'),
      url('../../dist/fonts/dm-sans/DMSans-BoldItalic.ttf') format('truetype'),
      url('../../dist/fonts/dm-sans/DMSans-BoldItalic.svg#DMSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../../dist/fonts/dm-sans/DMSans-SemiBoldItalic.eot');
  src: url('../../dist/fonts/dm-sans/DMSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../../dist/fonts/dm-sans/DMSans-SemiBoldItalic.woff2') format('woff2'),
      url('../../dist/fonts/dm-sans/DMSans-SemiBoldItalic.woff') format('woff'),
      url('../../dist/fonts/dm-sans/DMSans-SemiBoldItalic.ttf') format('truetype'),
      url('../../dist/fonts/dm-sans/DMSans-SemiBoldItalic.svg#DMSans-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../../dist/fonts/dm-sans/DMSans-MediumItalic.eot');
  src: url('../../dist/fonts/dm-sans/DMSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../../dist/fonts/dm-sans/DMSans-MediumItalic.woff2') format('woff2'),
      url('../../dist/fonts/dm-sans/DMSans-MediumItalic.woff') format('woff'),
      url('../../dist/fonts/dm-sans/DMSans-MediumItalic.ttf') format('truetype'),
      url('../../dist/fonts/dm-sans/DMSans-MediumItalic.svg#DMSans-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../../dist/fonts/dm-sans/DMSans-SemiBold.eot');
  src: url('../../dist/fonts/dm-sans/DMSans-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../../dist/fonts/dm-sans/DMSans-SemiBold.woff2') format('woff2'),
      url('../../dist/fonts/dm-sans/DMSans-SemiBold.woff') format('woff'),
      url('../../dist/fonts/dm-sans/DMSans-SemiBold.ttf') format('truetype'),
      url('../../dist/fonts/dm-sans/DMSans-SemiBold.svg#DMSans-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../../dist/fonts/dm-sans/DMSans-Medium.eot');
  src: url('../../dist/fonts/dm-sans/DMSans-Medium.eot?#iefix') format('embedded-opentype'),
      url('../../dist/fonts/dm-sans/DMSans-Medium.woff2') format('woff2'),
      url('../../dist/fonts/dm-sans/DMSans-Medium.woff') format('woff'),
      url('../../dist/fonts/dm-sans/DMSans-Medium.ttf') format('truetype'),
      url('../../dist/fonts/dm-sans/DMSans-Medium.svg#DMSans-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../../dist/fonts/dm-sans/DMSans-Regular.eot');
  src: url('../../dist/fonts/dm-sans/DMSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../dist/fonts/dm-sans/DMSans-Regular.woff2') format('woff2'),
      url('../../dist/fonts/dm-sans/DMSans-Regular.woff') format('woff'),
      url('../../dist/fonts/dm-sans/DMSans-Regular.ttf') format('truetype'),
      url('../../dist/fonts/dm-sans/DMSans-Regular.svg#DMSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../../dist/fonts/dm-sans/DMSans-Bold.eot');
  src: url('../../dist/fonts/dm-sans/DMSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../dist/fonts/dm-sans/DMSans-Bold.woff2') format('woff2'),
      url('../../dist/fonts/dm-sans/DMSans-Bold.woff') format('woff'),
      url('../../dist/fonts/dm-sans/DMSans-Bold.ttf') format('truetype'),
      url('../../dist/fonts/dm-sans/DMSans-Bold.svg#DMSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

