.cta {
  padding: 10rem 0;
  color: $color-background;
  position: relative;

  @media screen and (max-width: 991px) {
    padding: 6rem 0;
  }
  @media screen and (max-width: 575px) {
    padding: 3rem 0;
  }

  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: $trans;
  }

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.6);
    @media screen and (max-width: 767px) {
      background: rgba($color: #000000, $alpha: 0.7);
    }
  }

  &__container {
    position: relative;
    z-index: 3;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 575px) {
      align-items: flex-end;
    }
  }

  &__content {
    text-align: center;
    @media screen and (min-width: 768px) {
      width: 70%;
    }
    @media screen and (min-width: 991px) {
      width: 65%;
    }
    @media screen and (max-width: 767px) {
      width: 70%;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  &__titulo {
    font-weight: bold;
    font-size: 3.4rem;
    margin-bottom: 1rem;
    @media screen and (max-width: 1199px) {
      font-size: 2.6rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 2rem;
    }
  }

  &__subtitulo {
    color: $color-white;
    border-radius: 3px;
    font-weight: 500;
    margin-bottom: 3rem;
    font-size: 1.25rem;
    @media screen and (max-width: 991px) {
      margin-bottom: 1rem;
      font-size: 1rem;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    @media screen and (max-width: 767px) {
      margin-top: 1rem;
    }
    @media screen and (max-width: 575px) {
      gap: 1rem;
    }
    .boton {
      @media screen and (max-width: 575px) {
        width: 100%;
        text-align: center;
        justify-content: center;
      }
    }
  }
}
