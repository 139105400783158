.woo-page {
  padding-bottom: $space-2;
  &__wwrapper {
    display: grid;
    grid-template-columns: 2.5fr 10fr;
    gap: 3rem;
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      gap: $space-1;
    }
  }

  &__side {
    &--desktop {
      @media screen and (max-width: 991px) {
        display: none;
        visibility: hidden;
      }
    }

    &--mobile {
      @media screen and (min-width: 992px) {
        display: none;
        visibility: hidden;
      }
    }
  }

  &__filtros-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: max-content;
    border-radius: 4px;
  }

  &__products {
  }
}

.grid-woo {
  display: flex;
  &__sidebar {
    display: block;
    @media (max-width: 767px) {
      display: none;
    }
  }
  &__sidebar-responsive {
    display: block;
    @media (min-width: 768px) {
      display: none;
    }
  }

  &__content {
  }
}

.widget--woo {
  margin-bottom: 0rem;
  @media (max-width: 575px) {
    margin-bottom: 1rem;
  }
  h4.widget__title {
    display: none;
  }
  input {
    box-shadow: none !important;
  }
}

.single-product {
  .grid-woo {
    display: block;
  }
  .grid-woo__sidebar-responsive,
  .grid-woo__sidebar {
    display: none;
  }
}

// .tax-product_cat .grid-woo,
// .post-type-archive-product .grid-woo {
//   margin-top: 2rem !important;
//   @media screen and (max-width: 767px) {
//     margin-top: 0.5rem !important;
//   }
// }

.page-woocommerce {
  padding-top: 2rem;
  padding-bottom: 4rem;
  &--product {
    padding-top: 0;
    // background-color: $color-background;
  }
}

.term-description {
  margin-top: 2.5rem;
}

ul.products {
  padding-left: 0;
  list-style: none;
  display: grid;
  width: 100%;
  margin-top: 1rem;
  @media screen and (max-width: 575px) {
    // padding-left: 2rem;
    // padding-right: 2rem;
  }
  li.product {
    // padding: 1rem;
    position: relative;
    background-color: $color-background;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .added_to_cart {
      position: relative;
      bottom: -8px;
      left: 5px;
      background-color: $color-primary-light;
      width: fit-content;
      padding: 6px 12px;
      border-radius: 4px;
      line-height: 1;
      font-size: $font-size-small;
    }
  }

  .product__cat {
    margin-bottom: calc($font-size / 3);
    color: $color-grey-3;
    font-size: 0.75rem;
    letter-spacing: 0.5px;
    @media (max-width: 575px) {
      margin-bottom: 0.2rem !important;
    }
  }

  .woocommerce-loop-product__link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
    position: relative;
    text-align: left;
    @media (max-width: 575px) {
      align-items: flex-start;
    }
    .onsale {
      position: absolute;
      background-color: $color-accent;
      color: $color-white;
      padding: 3px 8px 4px;
      border-radius: 50px;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: bold;
      top: 0.5rem;
      left: 0.5rem;
      @media screen and (max-width: 575px) {
        font-size: 0.7rem;
        padding: 2px 8px 3px;
      }
    }
    .attachment-woocommerce_thumbnail,
    .woocommerce-placeholder {
      width: 100% !important;
      height: auto;
      margin-bottom: $font-size-smaller;
      border-radius: 7px;
      aspect-ratio: 1;
      object-fit: contain;
      border: 1px solid rgba($color: #000000, $alpha: 0.1);
      background: $color-white;
      @media (max-width: 575px) {
        margin-bottom: 0.8rem !important;
      }
    }
  }

  .woocommerce-loop-product__title {
    font-size: $font-size-small;
    margin-top: 0;
    margin-bottom: calc($font-size / 2);
    font-weight: $font-weight-medium;
    display: block;
    color: $color-dark;
    min-height: 35px;
    text-wrap: balance;
    @media screen and (max-width: 1199px) {
      min-height: auto;
    }
  }

  .berocket_better_labels {
    .br_alabel {
      span {
        font-weight: $font-weight-medium !important;
      }
    }
  }

  // .woocommerce-productPrice-container,
  .price {
    .woocommerce-Price-amount {
      bdi {
        color: $color-primary-dark;
        font-size: $font-size;
        font-weight: $font-weight-bold;
        @media screen and (max-width: 767px) {
          font-size: $font-size-small;
        }
      }
    }

    & > del {
      text-decoration: none !important;
      .woocommerce-Price-amount {
        bdi {
          color: $color-grey-3;
          font-weight: $font-weight-normal;
          font-size: 80%;
          text-decoration: line-through;
        }
      }
    }

    & > ins {
      text-decoration: none !important;
      .woocommerce-Price-amount {
        margin-left: 0.3rem;
        text-decoration: none !important;
        bdi {
          color: $color-red;
          font-weight: bold;
          font-size: $font-size;
        }
      }
    }

    // ins {
    //   bdi {
    //     color: $color-red;
    //     font-weight: bold;
    //     font-size: $font-size;
    //     margin-left: 0.3rem;
    //     text-decoration: none !important;
    //   }
    // }
    // del {
    //   bdi {
    //     color: $color-grey-4;
    //     font-size: 70%;
    //     text-decoration: none !important;
    //   }
    // }
  }

  .woocommerce-loop-product__precio-kilo {
    color: $color-grey-4;
    font-size: 12px;
  }
}

.single-product .woocommerce-loop-product__precio-kilo {
  color: $color-grey-4;
  font-size: $font-size-small;
  margin-bottom: $font-size;
}

// .top ul.products {
//   display: flex;
//   width: 100%;
//   margin-top: 0;
//   margin-bottom: 0.5rem;
//   li.product {
//     margin: 0 10px;
//   }
// }

.products.columns-3 {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
}

.products.columns-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: $space-4 1.25rem;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem 1rem;
  }
  @media (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem 1rem;
  }
}

.products.columns-5 {
  grid-template-columns: repeat(5, 1fr);
  gap: $space-3 $space-1;
  @media screen and (max-width: 1199px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem 1rem;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem 2rem;
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem 1rem;
  }
  @media (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem 1rem;
  }
}

.products.columns-6 {
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2rem;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
}

.products {
  li.product-category {
    img {
      width: 100%;
      height: auto;
      border: 0px solid rgba($color: #000000, $alpha: 0.1);
      margin-bottom: 1.5rem;
    }
    h2.woocommerce-loop-category__title {
      font-size: 1rem;
      color: $color-grey-4;
      text-align: center;
    }
  }
}

.term-description {
  margin-bottom: 1rem;
  font-size: 0.85rem;
}

.woocommerce-result-count {
  display: inline-block;
  color: $color-grey-4;
  margin-top: 10px;
  font-size: $font-size-small;

  @media (max-width: 575px) {
    font-size: $font-size-small;
    display: none;
  }
}

.woocommerce-ordering {
  display: inline-block;
  float: right;
  clear: both;
  @media (max-width: 991px) {
    float: none;
  }
}

/* Paginador -------------------- */
.woocommerce-pagination {
  text-align: center;
  display: none;
  visibility: hidden;

  ul.page-numbers {
    list-style: none;
    display: inline-flex;
    justify-content: center;
    width: auto !important;
    border: 0px solid $color-primary;
    padding: 2px;
    border-radius: 3px;
  }

  li {
    display: inline-block;
  }

  li .page-numbers {
    padding: 10px 20px;
    background-color: $color-primary;
    color: #fff;
    margin: 2px;
    display: block;
    border-radius: 3px;
    font-size: 1.2rem;
  }

  li .page-numbers.current {
    background-color: $color-primary-dark;
  }
  li .page-numbers:hover {
    background-color: $color-primary-dark;
  }
}
