.woo-cart {
  padding-bottom: $space-2;
}

.page-id-14154 .woocommerce {
  display: grid;
  grid-template-columns: 3.3fr 2fr;
  grid-column-gap: 2rem;
  grid-row-gap: 0;
  @media screen and (max-width: 1199px) {
    grid-template-columns: 3fr 2fr;
  }
  @media (max-width: 991px) {
    display: block;
  }
  .woocommerce-notices-wrapper {
    grid-column: 1 / 3;
  }
}

.cart_totals,
.shop_table {
  width: 100%;
}

.page-template-page-cart {
  background-color: $color-grey-6;
}

/* Tabla del carrito de la compra CELDAS INDIVIDUALES */
.woocommerce-cart-form {
  margin-bottom: 2rem;

  .shop_table {
    width: 100%;
  }

  .product-remove {
    width: 40px;
    font-size: 1.2rem;
    text-align: center;
    &::before {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .product-remove a {
    font-size: 1.5rem;
    color: rgba(129, 13, 13, 0.774) !important;
  }

  .product-thumbnail {
    display: table-cell;
    align-items: center;
    height: 100% !important;
  }

  .product-thumbnail a {
  }

  .product-thumbnail img {
    width: 50px;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  .product-name {
    width: 50%;
    text-align: left;
    font-size: 1rem;
    font-weight: $font-weight-medium;
    line-height: 1rem !important;
    .variation {
      display: grid;
      grid-template-columns: 75px auto;
      gap: 2px 10px;
      font-size: $font-size-small;
      margin: 5px 0;
      @media screen and (max-width: 767px) {
        grid-template-columns: 3fr 1fr;
      }
      dt {
        margin-right: 5px;
        font-weight: normal;
        margin-bottom: 3px;
        // flex: 0 0 30% !important;
      }
      dd {
        margin-left: 0;
        margin-right: 5px;
        margin-bottom: 3px;
        // flex: 0 0 80% !important;

        p {
          margin: 0;
          display: inline-flex;
        }
      }
    }
  }

  .product-price {
    text-align: right;
  }

  .product-quantity {
    text-align: right;

    @media screen and (max-width: 991px) {
      display: flex !important;
      justify-content: flex-end;
      width: 100%;
    }
    .quantity {
      margin-left: auto;
      display: flex;

      .button {
        padding: 3px;
        background: $color-white;
        border-radius: 1px !important;
        width: 20px;
        color: $color-grey-1;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        &:hover {
          transform: none !important;
          background: $color-grey-5;
        }
        &.minus {
          border-radius: 4px 0 0 4px !important;
          border: 1px solid $color-grey-3;
          border-right: none;
        }
        &.plus {
          border-radius: 0 4px 4px 0 !important;
          border: 1px solid $color-grey-3;
          border-left: none;
        }
      }
      .qty {
        padding: 8px 3px;
        height: 30px;
        width: 50px;
        font-size: $font-size-small;
        border-radius: 0 !important;
        border: 1px solid $color-grey-3;
        border-left: none;
        border-right: none;
      }
    }
  }

  .product-subtotal {
    font-weight: bold;
    text-align: right;
    .tax_label {
      font-size: 11px !important;
      font-weight: normal;
      display: block;
    }
  }

  thead {
    tr th {
      height: 3rem;
      line-height: 3rem;
      font-size: 0.8rem !important;
      text-transform: uppercase;
    }
  }
}

/* Tabla del carrito de la compra -------------------------- */
.woocommerce-cart-form table.shop_table {
  @include card-cart;
  border-collapse: initial;
  thead {
    @media (max-width: 767px) {
      display: none;
    }
  }

  tbody {
    tr {
      @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
      }
    }
    td {
      @media (max-width: 767px) {
        display: block;
        width: 100%;
        text-align: right;
        font-size: 0.9rem;
      }
    }
    td.product-thumbnail {
      @media (max-width: 767px) {
        display: none;
      }
      img {
        background-color: $color-background;
      }
    }
    td.product-name {
      font-size: $font-size-small;
      a {
        color: $color-accent-dark;
      }
      .rp_wcdpd_promotion_cart_item_description {
        margin-bottom: $font-size-smaller;
        font-weight: $font-weight-normal;
        margin-bottom: 0;
        margin-top: 0.3rem;
      }
    }
  }

  .cart_item {
    @media (max-width: 767px) {
      border: 0px solid rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      padding: 5px 5px 4px 5px;
      margin-bottom: 10px;
    }
  }
  .cart_item td {
    border-bottom: 1px solid $color-grey-5;
    // background-color: rgba(0, 0, 0, 0.03);
    padding-top: calc($font-size / 2);
    padding-bottom: calc($font-size / 2);
    @media screen and (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  td {
    padding: 5px 9px;
    vertical-align: middle;
    line-height: 1.5em;
  }

  td a {
    color: $color-dark;
  }

  th {
    font-weight: 700;
    padding: 7px 9px;
    line-height: 1.5em;
  }

  .actions {
    padding: 1rem 0 0 0;
    &::before {
      @media (max-width: 575px) {
        display: none;
      }
    }
    .button {
      background-color: $color-grey-2;
      color: $color-white;
    }
    .button[disabled] {
      cursor: not-allowed;
      box-shadow: none;
      color: $color-background;
    }
  }
}

.coupon {
  @media screen and (max-width: 767px) {
    float: none;
  }
  label {
    display: none;
  }
  @media (max-width: 767px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
  }
  input {
    @media (max-width: 767px) {
      margin-right: 10px;
      width: 50%;
    }
  }
}

/* Atributos en tabla para responsive --------------- */
.woocommerce table.shop_table_responsive tr td::before,
.woocommerce-page table.shop_table_responsive tr td::before {
  @media (max-width: 767px) {
    content: attr(data-title) ": ";
    font-weight: 400;
    float: left;
  }
}

.cart_totals table.shop_table {
  // border: 1px solid rgba(0, 0, 0, .1);
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
  border-collapse: separate;
  border-radius: 5px;
}

.woocommerce table.shop_table .screen-reader-text {
  display: none;
}

.update_cart {
  float: right;
}

.cart-collaterals {
  width: 100%;
  display: flex;
  justify-content: right;
}

/* CARRITO TOTALES Y TRANSPORTE ------------------- */

.cart_totals {
  display: block;

  @media (max-width: 767px) {
    width: 100%;
    font-size: 0.9rem !important;
  }
  h2 {
    font-size: 1.5rem !important;
  }
  .shop_table {
    // border: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
    border-radius: 3px;
    @include card-cart;
  }

  tbody tr td,
  tbody tr th {
    padding: 12px 12px !important;
  }

  tbody tr td {
    border: 1px solid rgba(0, 0, 0, 0.05);
    &::before {
      display: none;
    }
  }

  tbody tr th {
    font-size: $font-size-small;
    text-align: right;
    width: 25%;
    @media (max-width: 767px) {
      width: 25%;
    }
  }

  tbody tr th:nth-child(1) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  h2 {
    margin-top: 0;
  }

  .checkout-button {
    display: block;
    font-size: 1.3rem;
    text-align: center;
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
  }
}

.woocommerce-shipping-methods {
  padding-left: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 5px !important;

  li {
    label {
      font-weight: bold;
    }

    label span {
      font-weight: normal;
    }

    .betrs_option_desc {
      font-size: $font-size-small;
    }
  }
}

.woocommerce-cart .cross-sells {
  @media screen and (max-width: 767px) {
    display: none;
  }
  margin-top: $space-2;
  > h2 {
    margin-top: 2rem;
    font-size: $header-4 !important;
    font-weight: $font-weight-bold;
    color: $color-grey-1 !important;
    @media screen and (max-width: 767px) {
      font-size: 1.2rem !important;
      line-height: 1.4 !important;
    }
  }
  ul.products {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: $space-2 $font-size;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }
    li {
      background: none !important;
    }
  }
}

.woocommerce #content table.cart td.actions,
.woocommerce table.cart td.actions,
.woocommerce-page #content table.cart td.actions,
.woocommerce-page table.cart td.actions {
  text-align: right;
}

.woocommerce #content table.cart td.actions .coupon,
.woocommerce table.cart td.actions .coupon,
.woocommerce-page #content table.cart td.actions .coupon,
.woocommerce-page table.cart td.actions .coupon {
  float: left;
}

table.shop_table {
  .cart-discount {
    background-color: #ffdbe9;
    th,
    td {
      border-top: 1px solid #ff0066;
      border-bottom: 1px solid #ff0066;
    }
  }
}

// @media (max-width: 767px) {
//   .woocommerce table.shop_table_responsive tr td::before,
//   .woocommerce-page table.shop_table_responsive tr td::before {

//     content: attr(data-title) ": ";
//     font-weight: 700;
//     float: left;
//   }
// }

.hook-cart {
  margin-top: 2rem;
  margin-bottom: 1rem;
  // display: none;

  &--eu {
    display: none;
  }

  &__suma {
    border: 1px dashed rgb(24, 124, 62);
    border-radius: $border-radius;
    padding: 5px 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    @media screen and (max-width: 575px) {
      border-width: 2px;
      width: 100%;
      padding: 9px 1rem;
    }
    &__icono {
      margin-right: 2rem;
      @media screen and (max-width: 767px) {
        margin-right: 1rem;
      }
      img {
        width: 70px;
        height: auto;
        @media screen and (max-width: 767px) {
          width: 100px;
        }
      }
    }
    &__content {
      line-height: 1.2;
      font-size: 1.2rem;
      strong {
        color: rgb(24, 124, 62);
        @media screen and (max-width: 767px) {
          // display: block;
        }
      }
    }
  }
  &__gratuito {
    text-align: center;
  }
}

.off-carrito {
  &__title {
    margin-bottom: 1rem;
    font-size: $font-size-small;
  }
  .hook-cart {
    font-size: $font-size-small;
  }
}

// Mini cart_totals

.widget_shopping_cart {
  &_content {
    ul.product_list_widget {
      padding-left: 0;

      li.mini_cart_item {
        list-style: none;
        background-color: $color-grey-6;
        padding: 0.5rem;
        border-radius: $border-radius;
        margin-bottom: 0.5rem;
        position: relative;
        a.remove {
          position: absolute;
          right: 1rem;
          top: 1rem;
        }
        a {
          .attachment-woocommerce_thumbnail,
          .wp-post-image {
            width: 50px;
            height: auto !important;
            border: 1px solid $color-grey-5;
            margin-right: 1rem;
            border-radius: $border-radius-min;
          }
        }
        .quantity {
        }
      }
    }
    .woocommerce-mini-cart {
      &__buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .button {
          border-radius: 50px;
          width: 100%;
          text-align: center !important;
          justify-content: center;
          margin-bottom: calc($space-1 / 2);
          font-size: $font-size !important;
          letter-spacing: 0;
        }
        .wc-forward {
          font-weight: 400;
          background-color: $color-grey-3;
        }
        .checkout.wc-forward {
          background-color: $color-accent;
          padding: 0.85rem 1.875rem !important;
        }
      }
      &__total {
        border-top: 1px dashed $color-grey-4;
        padding-top: 2rem;
        margin-top: 2rem;
        font-size: $font-size-big;
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        .amount {
          color: $color-dark;
        }
      }
      &__empty-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          display: flex;
          padding: 1rem 0;
        }
        img {
          width: 100px;
          height: auto;
        }
      }
    }
  }
}

.m-cart {
  display: flex;
  padding-right: 2rem;
  &__pic {
  }
  &__content {
    font-size: $font-size-small;
    .quantity {
      display: block;
      font-size: $font-size-smaller;
      color: $color-grey-3;
      bdi {
        color: $color-grey-1;
      }
    }
  }
  .variation {
    color: $color-grey-3;
    p {
      margin-bottom: 0;
    }
    display: flex;
    flex-wrap: wrap;
    dd,
    dt {
      flex-basis: 40%;
      margin-bottom: 0;
    }
  }
}

.wd-empty-mini-cart::before {
  display: block;
  margin-bottom: 20px;
  color: rgba(135, 135, 135, 0.15);
  font-size: 86px;
  line-height: 1;
  content: "\f147";
  font-family: "woodmart-font";
}

.fgf-progress-bar-wrapper {
  padding: $space-1;
  border: 1px dashed $color-grey-4;
  margin-bottom: $space-1 !important;
  border-radius: $border-radius;
  .fgf-progress-bar-heading-label {
    font-weight: $font-weight-bold;
    color: $color-primary;
  }
}

.fgf_gift_products_wrapper {
  h3 {
    font-weight: $font-weight-bold;
    text-transform: none;
    color: $color-primary;
  }
}

.fgf-gift-products-content {
  margin-bottom: $space-1;
  .button.fgf-add-manual-gift-product {
    color: $color-background;
    padding: 0.45rem 0.7rem;
  }
  .fgf-product-variations {
    font-size: $font-size-small;
    padding-right: 40px;
    max-width: 200px !important;
    @media screen and (max-width: 767px) {
      margin-bottom: 5px;
    }
  }
  td[data-title="Product Name"] {
    font-size: $font-size-small;
    @media screen and (max-width: 767px) {
      font-weight: $font-weight-bold;
    }
  }
  td[data-title="Product Image"] {
    img {
      border: 1px solid $color-grey-5;
    }
  }
  tbody {
    tr {
      td {
        border-bottom: 1px solid $color-grey-5;
        @media screen and (max-width: 767px) {
          border-bottom: initial;
        }
        &:before {
          @media screen and (max-width: 767px) {
            font-size: $font-size-smaller;
            display: none;
          }
        }
      }
      &:nth-child(even) {
        background-color: $color-grey-6;
      }
    }
  }
}
