.compres {
  padding: 2rem 0;
  background-color: $color-grey-6;
  &__container {
  }

  &__content {
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: 575px) {
      flex-direction: column;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    @media screen and (max-width: 575px) {
      margin-bottom: 1rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icono {
    @media screen and (max-width: 575px) {
      flex: 0 0 10%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    i {
      font-size: 2.5rem;
      color: $color-primary-dark;
    }
  }

  &__titulo {
    font-weight: 500;
    font-size: $font-size-small;
    color: $color-black;
    span {
      display: block;
      color: $color-grey-1;
    }
  }
}
