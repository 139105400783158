.col2-set {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
  .col-2,
  .col-1 {
    width: 100% !important;
  }
}

#order_review_heading,
.woocommerce-billing-fields h3,
.woocommerce-billing-fields h3 label span {
  font-size: $font-size !important;
  margin-top: 0;
  color: $color-secondary !important;
  text-transform: uppercase;
}

.woocommerce-address-fields,
.woocommerce-checkout {
  label {
    display: block;
    margin-bottom: 2px;
    font-weight: 500;
    font-size: 0.9rem;
  }

  .woocommerce-input-wrapper {
    display: block;
    width: 100%;
  }

  input,
  textarea,
  select {
    display: block;
    width: 100%;
  }
}

.woocommerce-billing-fields__field-wrapper {
}

.form-row-wide {
  margin-top: 0 !important;
}

#billing_phone_field,
#billing_postcode_field,
.form-row-first {
  @media (min-width: 992px) {
    width: 49%;
    display: inline-block;
    margin-right: 1%;
  }
}

#billing_email_field,
#billing_city_field,
.form-row-last {
  @media (min-width: 992px) {
    width: 49%;
    display: inline-block;
  }
}

/* checkout ---------------------------------- */

form.woocommerce-checkout {
  padding-bottom: $space-3;
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    gap: 1rem 3rem;
  }

  #customer_details {
    @media (min-width: 992px) {
      grid-column: 1 / 2;
      grid-row: 1 / 3;
    }
  }
  #order_review_heading {
    @media (min-width: 992px) {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
  }
  #order_review {
    @media (min-width: 992px) {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }
  }

  .woocommerce-additional-fields {
    @media screen and (max-width: 767px) {
      margin-top: $space-1;
    }
  }
}

#customer_details.col2-set {
  @media (min-width: 992px) {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}

/* checkout ---------------------------------- */

.woocommerce-form__label-for-checkbox {
  // display: flex !important;
  // justify-content: flex-start;
  // align-items: flex-start;

  .woocommerce-terms-and-conditions-checkbox-text {
    display: inline;
    font-size: $font-size-small;
  }

  .woocommerce-form__input-checkbox {
    width: auto;
    margin-right: 10px;
    margin-top: 5px;
    display: inline;
  }
}

.woocommerce-table--order-details,
.woocommerce-checkout-review-order .shop_table {
  @include card-cart;
  box-shadow: none !important;
  margin-bottom: 3rem;
}

.woocommerce-table--order-details,
.woocommerce-checkout-review-order-table {
  thead {
    tr {
      th {
        text-align: left;
        padding: 9px 12px;
        font-size: 1.3rem;

        @media (max-width: 767px) {
          padding: 5px 8px;
        }
      }
    }
  }

  tfoot {
    tr.fee {
      background-color: rgb(255, 244, 244) !important;
    }
  }

  tfoot tr th,
  tfoot tr td,
  tbody tr td {
    padding: 9px 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-align: left;

    @media (max-width: 767px) {
      padding: 5px 8px !important;
    }
    @media screen and (max-width: 575px) {
      font-size: $font-size-small;
    }
  }
}

.woocommerce-checkout-review-order-table {
  font-size: 0.9rem;
  thead .product-total,
  thead .product-name {
    font-size: 1.1rem;
    @media screen and (max-width: 575px) {
      font-size: $font-size;
    }
  }
  tbody td.product-name {
  }
}

.woocommerce-checkout-payment {
  background-color: $color-white;
  border: 1px solid $color-grey-4;
  padding: calc($space-1);
  border-radius: 5px;

  @media (max-width: 767px) {
    padding: $font-size;
  }

  ul.wc_payment_methods {
    list-style: none;
    padding-left: 0;
    margin-bottom: 2rem !important;

    li.wc_payment_method {
      padding-right: 1rem;
      margin-bottom: calc($font-size / 2);
    }
    li.wc_payment_method > label {
      font-weight: $font-weight-bold;
    }
    li.wc_payment_method > label > img {
      margin-left: 10px;
      margin-right: 10px;
      @media screen and (max-width: 575px) {
        max-width: 40px !important;
        max-height: 23px !important;
      }
    }

    li.wc_payment_method.payment_method_redsys_bizum_gw img {
      width: 80px;
      @media screen and (max-width: 575px) {
        width: 60px !important;
        max-width: 60px !important;
      }
    }

    li.wc_payment_method.payment_method_klarna_payments_pay_later a {
      @media screen and (max-width: 480px) {
        display: none;
      }
    }

    .input-radio {
      display: inline-block;
      width: auto;
      margin-right: 0.5rem;
    }

    .payment_box {
      width: 100%;
      margin: 10px 1.5rem 10px 1.5rem;
      padding: 0.5rem 1rem;
      font-size: $font-size-small;
      border-radius: calc($border-radius-min);
      background-color: $color-grey-6;
      @media screen and (max-width: 575px) {
        font-size: $font-size-smaller;
      }
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    label {
      display: inline-flex;
      align-items: center;
      font-weight: $font-weight-medium;
      font-size: $font-size-small;
    }
  }
}

.place-order button.button {
  background-color: $color-accent !important;
  text-align: center;
  padding: 1.2rem 1.5rem;
  font-size: 1.2rem;

  @media (max-width: 767px) {
    display: block !important;
    width: 100% !important;
  }
  @media (min-width: 991px) {
    display: block !important;
    width: 100% !important;
  }
}

.woocommerce-terms-and-conditions-wrapper {
  .woocommerce-privacy-policy-text {
    font-size: 0.8rem;
  }

  a {
    color: $color-primary-dark;
  }
}

.woocommerce-terms-and-conditions-checkbox-text {
}

/* Títulos ------------------------- */
.cart-empty,
.woocommerce-thankyou-order-received,
h2.wc-bacs-bank-details-heading,
h2.woocommerce-order-details__title {
  margin-top: 0;
  padding-top: 1rem;
  font-size: 2rem !important;
  color: $color-primary-dark;
  font-weight: $font-weight-bold;
  margin-bottom: 1rem;

  @media (max-width: 767px) {
    font-size: 1.3rem !important;
  }
}

.wc-bacs-bank-details-account-name {
  margin-top: 0;
}

/* Margin bottoms ------------------------- */
.order_details,
.woocommerce-bacs-bank-details {
  margin-bottom: 3rem !important;
}

.order_details,
.wc-bacs-bank-details,
.woocommerce-order-overview {
  list-style: none;
  padding: 0;
  background-color: $color-background;
  padding: 1.5rem 1rem 1rem 1.5rem;
  display: flex;
  font-size: 0.9rem;
  border: 1px solid $color-grey-6;
  border-radius: $border-radius;

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 1rem;
  }

  li {
    margin-right: 1.5rem;
    padding-right: 1.5rem;
    border-right: 1px solid $color-grey-5;

    @media (max-width: 767px) {
      border-right: 0;
    }
  }

  li:last-child {
    @media (max-width: 767px) {
      margin-bottom: 0 !important;
    }
  }

  li strong {
    display: block;
  }
}

/* Order recibed ------------------------- */

.woocommerce-table--order-details {
  display: block !important;
  box-shadow: initial;
}

#nxshop_spot_selection {
  @include input-buttons;
  background-color: $color-accent;
  color: #fff;
  @media (max-width: 767px) {
    display: block;
    text-align: center;
  }
}

#nxshop_table_container {
  @include card-cart;
  box-shadow: none !important;
  border: 1px solid $color-grey-4 !important;
  font-size: 0.85rem;
  margin-top: 1rem;
  table {
    tbody {
      tr td {
        vertical-align: middle !important;
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

.woocommerce-account-fields {
  .create-account {
    padding: 1rem;
    border: 1px dashed $color-grey-5;
    border-radius: 1rem;
    color: $color-primary;
  }
}

.woocommerce-form-login-toggle,
.woocommerce-form-coupon-toggle {
  margin-bottom: 2rem;
  @media screen and (max-width: 575px) {
    margin-bottom: 1rem;
  }
  .woocommerce-info {
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
    a {
      @media screen and (max-width: 767px) {
        display: block;
      }
    }
  }
}

.page-template-page-cart {
  .woocommerce-form-login {
    margin-bottom: 2rem;
    > p {
      font-size: $font-size-small;
    }
    input {
      display: block;
    }
    .form-row {
      display: block;
      width: 100%;
    }
    .woocommerce-form__label-for-checkbox {
      display: flex;
    }
  }
}
