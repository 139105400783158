.variations_form.wvs-loaded {
  .woo-selected-variation-item-name {
    display: none;
    visibility: hidden;
  }

  .value.woo-variation-items-wrapper {
    margin-bottom: $space-1;
    &:last-child {
      margin-bottom: 0;
    }
    & > ul {
      gap: 0.5rem 0 !important;
    }
  }
}
