.footer {
  padding: $space-3 0 $space-1;
  background-color: $color-dark;
  box-shadow: 0 50vh 0 50vh $color-dark;
  &__cols {
    display: flex;
    justify-content: space-between;
    margin-bottom: $space-3;
    flex-wrap: wrap;
    gap: $space-1;
    @media screen and (max-width: 767px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $space-3 $font-size;
    }
    .widget-footer {
      @media screen and (max-width: 767px) {
        // margin-bottom: $space-2;
      }
    }
    &--bottom {
      align-items: center;
      margin-bottom: 0;
      padding: $space-1 0;
      border-top: 2px solid $color-grey-3;
      border-bottom: 2px solid $color-grey-3;
      @media screen and (max-width: 767px) {
        gap: $space-1;
      }
      .footer__col {
        flex: 1;
        display: flex;
        &--social {
          justify-content: center;
          @media screen and (max-width: 767px) {
            justify-content: flex-start;
          }
          .widget-footer {
            flex: initial;
          }
        }
        &--cards {
          justify-content: flex-end;
        }

        &--img {
          grid-column: 1 / span 2;
          img {
            width: 100%;
            height: auto;
            max-width: 300px;
          }
        }
      }
    }
  }

  .widget-rs.zoom-social-icons-widget {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media screen and (max-width: 767px) {
      justify-content: center;
    }
    ul {
      margin-left: -5px;
    }
  }

  &__copy {
  }

  .footer-copy {
    padding-top: $space-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $font-size-smaller;
    gap: 0;
    color: $color-text-footer;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      justify-content: center;
      gap: $space-1;
      font-size: $font-size-small;
      text-align: center;
    }
    p {
      margin-bottom: $space-05;
      text-align: center;
    }
    small {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    svg {
      width: 1rem;
      height: auto;
      path {
        fill: $color-secondary;
      }
    }
    a {
      color: $color-text-footer;
      font-size: $font-size-smaller;
      &:hover {
        color: $color-primary;
      }
    }
    &__design {
      font-size: $font-size-smaller;
    }
  }
}

.widget-footer {
  flex: 1;
  &__title {
    position: relative;
    color: $color-white;
    margin-bottom: $space-1;
    padding-bottom: $font-size;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
      text-transform: initial;
      font-weight: $font-weight-bold;
    }
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      width: 50px;
      height: 1px;
      background: $color-primary;
    }
  }
  & > div {
    font-size: $font-size-small;
    color: $color-white;
  }
  ul.menu {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    li {
      margin-bottom: 4px;
      a {
        padding: 4px 0;
      }
    }
  }
  .textwidget {
    p {
      color: $color-white;
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
      a {
        color: $color-primary;
      }
    }
  }
  a {
    color: $color-white;
    &:hover {
      color: $color-primary;
    }
  }
}

.widget {
  &__title {
    color: $color-primary;
  }
}

.widget--legal {
  .widget__title {
    font-size: $header-3;
    font-weight: $font-weight-bold;
    color: $color-primary-dark;
  }
  #menu-menulegal {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $space-05;
    & > li.menu-item {
      a {
        text-decoration: none;
        line-height: 1.5;

        color: $color-black;
        &:hover {
          color: $color-primary;
        }
      }
    }
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: $space-01;
      li a {
        @include button;
        padding: 0.25rem 0.5rem;
        background: $color-primary;
        color: $color-white !important;
        border-color: $color-primary;
        transition: $trans;
        &:hover {
          background: darken($color-primary, 5%);
          border-color: darken($color-primary, 5%);
          color: $color-white;
          box-shadow: $box-shadow-small;
          transform: translateY(-2px);
          transition: $trans;
        }
      }
    }
  }
}

.next-gen {
  padding-bottom: $space-2;
  background-color: $color-dark;
  @media screen and (max-width: 575px) {
    padding-bottom: $space-1;
  }
  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $space-1 $space-1;
    flex-wrap: wrap;
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      gap: calc($font-size / 2);
    }
  }

  &__logos {
    display: flex;
    gap: $space-1;
  }
  &__img {
    img {
      width: 180px;
      height: auto;
      @media screen and (max-width: 575px) {
        width: 100px;
      }
    }
  }

  &__description {
    color: $color-white;
    font-size: $font-size-smaller;
    @media screen and (max-width: 575px) {
      text-align: center;
    }
  }
}
